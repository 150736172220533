<template>
  <div
    class="TextInput"
    :class="[
      setInputStyle,
      {
        'has-error': !!errorMessage,
        success: meta.valid
      }
    ]"
  >
    <label :for="name">{{ label }}</label>
    <select
      ref="selectref"
      :name="name"
      :id="name"
      :value="inputValue"
      :placeholder="placeholder"
      @input="handleChange"
      @blur="handleBlur"
    >
      <option v-if="withBlankOption" value=""></option>
      <option
        v-for="option in options"
        :key="option.id"
        :value="option.id"
        >{{ option.text || option.name }}</option
      >
    </select>
    <p class="help-message" v-show="errorMessage || meta.valid">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>

<script>
import { useField } from "vee-validate";
import { watch } from "vue";
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Object, Number],
      default: null
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: [String, undefined],
      required: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    successMessage: {
      type: String,
      default: ""
    },
    withBlankOption: {
      type: Boolean,
      default: true
    }
  },
  emits: ["error-update", "selected"],
  setup(props, { emit }) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta
    } = useField(props.name, undefined, {
      initialValue: props.value
    });
    watch(meta, () => {
      emit("error-update", meta.valid);
    });
    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta
    };
  },
  watch: {
    value(n) {
      if (!n) return;
      this.inputValue = n;
    },
    inputValue(n) {
      this.$emit("selected", n);
    }
  },
  computed: {
    selectedOptionID() {
      return this.selectedOption?.id;
    },
    selectedOption() {
      return this.value;
    },
    setInputStyle() {
      return {
        "input-title": this.name == "title",
        "input-name": this.name == "first_name" || this.name == "last_name",
        "input-postcode": this.name == "postcode"
      };
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "@/styles/setup/variables";

.TextInput {
  position: relative;
  margin-bottom: calc(1em * 1.5);
  width: 100%;
}

label {
  display: block;
  margin-bottom: 4px;
  width: 100%;
}

select {
  border-radius: 5px;
  border: 2px solid transparent;
  padding: 15px 10px;
  outline: none;
  background-color: $inputBackground;
  width: 100%;
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
}

select:focus {
  border-color: var(--primary-color);
}

.help-message {
  position: absolute;
  bottom: calc(-1.5 * 1em);
  left: 0;
  margin: 0;
  font-size: 14px;
}

.TextInput.has-error select {
  background-color: var(--error-bg-color);
  color: var(--error-color);
}

.TextInput.has-error select:focus {
  border-color: var(--error-color);
}

.TextInput.has-error .help-message {
  color: var(--error-color);
}

.TextInput.success select {
  background-color: var(--success-bg-color);
  color: var(--success-color);
}

.TextInput.success select:focus {
  border-color: var(--success-color);
}

.TextInput.success .help-message {
  color: var(--success-color);
}
</style>
