export default {
  getApplicationExtractedText: state => state.mlState.applicationExtractedText,
  getDocumentSrc: state => state.mlState.documentSrc,
  getExtractedTextContent: state => state.mlState.textContent,
  getPDFPage: state => state.mlState.pdfPage,
  getPDFArray: state => state.mlState.pdfArray,
  getExtractedTextArray: state => state.mlState.extractedTextArray,
  getEmptyValueArray: state => state.mlState.emptyValueArray,
  getExtractedText: state => state.mlState.extractedText,
  getMLTextEntitiesConst: state => state.mlState.mLTextEntities,
  getMLTextSearchEntities: state => state.mlState.mLTextSearchEntities,
  getMLTextEntities: state =>
    state.mlState.mLTextSearchEntities.length > 0
      ? state.mlState.mLTextSearchEntities?.filter(
        e => e.FieldName.toLowerCase().indexOf("mix_your_options") === -1
      )
      : state.mlState.mLTextEntities?.filter(
        e => e.FieldName.toLowerCase().indexOf("mix_your_options") === -1
      ),
  getAllBlocks: state => {
    if (!state.mlState.mLTextBlocks) return;
    return state.mlState.mLTextBlocks;
  },
  getMLTextAllBlocks: state => {
    if (!state.mlState.mLTextBlocks) return;
    return state.mlState.mLTextBlocks.filter(b => b.blockType == "WORD");
  },
  getMLTextSearchBlocks: state => {
    if (!state.mlState.mLTextSearchBlocks) return;
    return state.mlState.mLTextSearchBlocks;
  },
  getMLTextBlocks: state => {
    if (!state.mlState.mLTextBlocks) return;
    return state.mlState.mLTextBlocks.filter(
      b => b.page == state.mlState.pdfPage + 1 && b.blockType == "WORD"
    );
  },
  getMLSelectedTextEntities: state => state.mlState.mLSelectedTextEntities,
  getMLSelectedTextEntitiesIndex: state =>
    state.mlState.mLSelectedTextEntitiesIndex,
  getMLSelectedTextEntitiesIndexArr: state =>
    state.mlState.mLSelectedTextEntitiesIndexArr,
  getMLTextUuid: state => state.mlState.mLTextUuid,
  getMLPage: state => state.mlState.mLPage,
  getMLPageCount: state => state.mlState.pdfPageCount,
  getMLEditEntity: state => state.mlState.mlEditEntity,
  getMLEntityDeactivate: state => state.mlState.mlDeactivate,
  getMLEditSelection: state => state.mlState.mlEditSelection,
  getMLFieldType: state => ind => state.mlState.mlFieldType[ind]?.type,
  getMLFieldTypeKeys: state => ind => Object.keys(state.mlState.mlFieldType),
  getMLFieldEnum: state => ind => state.mlState.mlFieldType[ind]?.values,
  getMLProcessedSelection: state => state.mlState.mlProcessedSelection,
  getSelecetedEntityIdList: state => state.mlState.entitySelectedIdList,
  getSelecetedEntityText: state => state.mlState.entitySelectedText,
  getMlInformationSource: state => state.mlState.mlInformationSource,
  getMlRuleType: state => state.mlState.ruleType,
  getRagEntityRules: state => state.mlState.ragEntityRules,
  getIsEntityUpdating: state => state.mlState.isEntityUpdating,
};
