import {axiosRequest} from "@/store/actions/abstract/axiosRequest";

export default {
    async saveTerm({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/spam-filter`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async updateTerm({getters}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/spam-filter/update`,
            "post",
            localStorage.getItem("token")
        );
    },
    async deleteTerm({getters}, id) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/spam-filter/${id}`,
            "delete",
            localStorage.getItem("token"),
        );
    },
    async fetchTerm({getters}, id) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/spam-filter/${id}`,
            "get",
            localStorage.getItem("token")
        );
    },
    async fetchAllTerms({getters}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/spam-filter`,
            "get",
            localStorage.getItem("token")
        );
    },
    async searchFilter({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/spam-filter/search`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
};
