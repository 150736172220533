<template>
  <div class="edit-wrap">
    <button class="edit-btn" name="button" type="button" @click="handleClick">
      <slot name="btn-text"></slot>
    </button>
    <transition mode="out-in" name="fade">
      <div v-if="show" class="edit-bg">
        <Close @close="handleClose"/>
        <div class="edit-control" @click.prevent="handleClose"></div>
        <div class="edit-modal">
          <h4 class="mb-3">
            <slot name="formTitle"></slot>
          </h4>
          <div class="card">
            <div class="card-body">
              <slot name="form"></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "EditModal",
  data: () => ({
    show: false
  }),
  emits: ["opened", "closed"],
  methods: {
    handleClose() {
      this.show = false;
      this.$emit("closed");
    },
    handleClick() {
      this.show = true;
      this.$emit("opened");
    }
  }
};
</script>
<style lang="scss" scoped>
.edit {
  &-btn {
    position: absolute;
    top: $spacingBase;
    right: $spacingBase;
    color: $brandBlue;
    text-decoration: underline;
    border: 0;
    background: transparent;
  }

  &-bg {
    position: fixed;
    overflow-y: scroll;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding-top: $spacingBase;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 998;
    @include blurFilter();
  }

  &-control {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
    z-index: 999;
  }

  &-modal {
    pointer-events: all;
    max-width: 800px;
    width: 100%;
    z-index: 1000;
  }

  &-wrap {
    &.-vw70 {
      .edit-modal {
        width: 70vw;
        max-width: none;
      }
    }

    &.-vw50 {
      .edit-modal {
        width: 50vw;
        max-width: none;
      }
    }

    &.-inline {
      .edit-btn {
        position: relative;
        top: initial;
        text-decoration: none;
      }
    }
  }
}

.-maximise-width {
  .edit-modal {
    max-width: 90vw;
  }
}

.-bottom-margin {
  table {
    margin-bottom: calc(#{ $spacingBase
  } * 4);
  }
}

.-overflow-visible {
  .table-responsive {
    overflow: visible;
  }
}

</style>
