<template>
  <div class="mb-4">
    <span class="position-absolute link-text end" @click="handleClear"
      >clear</span
    >
    <Form class="p-0" autocomplete="off">
      <TextInput
        ref="searchText"
        name="searchText"
        type="text"
        :label="label"
        class="m-0 text-left"
        :value="value"
        @updated="handleUpdate"
      />
    </Form>
    <slot name="search-results"></slot>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "SearchText",
  props: {
    label: {
      type: String,
      default: "Search"
    }
  },
  emits: ["search-updated", "search-clear"],
  data: () => ({
    value: ""
  }),
  computed: {
    ...mapGetters([])
  },
  methods: {
    ...mapMutations([]),
    handleClear() {
      this.handleUpdate("");
      this.$refs.searchText.clear();
      this.$emit("search-clear");
    },
    handleUpdate(value) {
      this.debounce(() => this.$emit("search-updated", value));
    }
  }
};
</script>
<style lang="scss" scoped>
.end {
  right: $cardBodyPadding;
  z-index: 10;
}
</style>
