import {axiosRequest} from "@/store/actions/abstract/axiosRequest";

export default {
    async fetchUnassignedApplications({getters, commit}) {
        let response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application-management/get-unassigned-applications`,
            "get",
            localStorage.getItem("token")
        );

        commit("setUnassignedApplication", response.data);

        return response;
    },
    async fetchAssignedApplications({getters, commit}) {
        let response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application-management/get-applications`,
            "post",
            localStorage.getItem("token"),
            {
                userId: getters.getUser.id
            }
        );

        commit("setAssignedApplication", response.data.data);
    },
    async fetchDuplicates({getters, commit}, {uuid, type}) {
        let response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application-management/get-duplicates`,
            "post",
            localStorage.getItem("token"),
            {
                uuid,
                type
            }
        );

        commit("setDuplicates", response.data.result);
    },
    async assignApplication({getters, commit}, {userId, applicationId}) {
        let response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application-management/assign-application`,
            "post",
            localStorage.getItem("token"),
            {userId, applicationId}
        );

        commit("setUnassignedApplication", response.data);

        return response;
    },
    async unassignApplication({getters, commit}, {userId, applicationId}) {
        let response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application-management/unassign-application`,
            "post",
            localStorage.getItem("token"),
            {userId, applicationId}
        );

        commit("setUnassignedApplication", response.data);

        return response;
    },
    async moveTracedPensions({getters, commit}, params) {
        let response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application-management/move-traced-pension`,
            "post",
            localStorage.getItem("token"),
            params
        );

        commit("setDuplicates", response.data.result);

        return response;
    },
    async copyApplicationData({getters, commit}, params) {
        let response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application-management/copy-application-data`,
            "post",
            localStorage.getItem("token"),
            params
        );

        commit("setDuplicates", response.data.result);

        return response;
    }
};
