import viewport from "@/helpers/viewport";

export function watchViewport(cb) {
  this.throttle = {};
  this.init(cb);
}

watchViewport.prototype = {
  init(cb) {
    window.onresize = () => this.resize(cb);
  },
  resize(cb) {
    clearTimeout(this.throttle);
    this.throttle = setTimeout(() => {
      cb(viewport.viewPortSize());
    }, 150);
  }
};
