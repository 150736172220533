export default {
    applications: null,
    files: null,
    withSig: false,
    noEmployerInput: false,
    application: {
        uuid: null,
        data: {
            uuid: null
        }
    },
    emailApplication: null,
    tracedPension: {
        uuid: null,
        data: {
            uuid: null
        }
    },
    employments: {
        uuid: null,
        data: {
            uuid: null
        }
    },
    aggregationReport: {
        data: {}
    },
    providerList: [],
    employmentList: [],
    providerDataTree: [],
    providersHashTable: {},
    statusClosedReason: [],
    statusAppList: [],
    finderEmployerStatus: [],
    statusTPList: [],
    setFinderStatus: false
};
