import {createRouter, createWebHistory} from "vue-router";
import {canUserAccess} from "./auth";

const routes = [
    {
        path: "/login",
        name: "Login",
        component: () => import(/* webpackChunkName: "Home" */ "../views/Login.vue")
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () =>
            import(/* webpackChunkName: "Dashboard" */ "../views/Dashboard.vue")
    },
    {
        path: "/",
        name: "Home",
        component: () =>
            import(
                /* webpackChunkName: "Dashboard" */
                "../views/Dashboard.vue"
                )
    },
    {
        path: "/application-form",
        name: "Application Form",
        component: () =>
            import(
                /* webpackChunkName: "ApplicationForm" */
                "../views/application/ApplicationForm.vue"
                )
    },
    {
        path: "/applications",
        name: "Applications",
        component: () =>
            import(
                /* webpackChunkName: "Applications" */
                "../views/application/Applications.vue"
                )
    },
    {
        path: "/application/:uuid?",
        name: "Application Focus",
        component: () =>
            import(
                /* webpackChunkName: "ApplicationsFocus" */
                "../views/application/ApplicationsFocus.vue"
                )
    },
    {
        path: "/application/:uuid?/traced-pension/:tpuuid?",
        name: "Traced Pension",
        component: () =>
            import(
                /* webpackChunkName: "TracedPension" */
                "../views/TracedPension.vue"
                )
    },

    // ml operation routes
    {
        path: "/ml-operations",
        name: "Fabric Translate",
        component: () =>
            import(
                /* webpackChunkName: "MLOperations" */
                "../views/ml-operations/MLOperations.vue"
                )
    },
    {
        path: "/ml-operations/:auuid/traced-pensions",
        name: "Fabric Translate Application",
        component: () =>
            import(
                /* webpackChunkName: "MLApplication" */
                "../views/ml-operations/MLApplication.vue"
                )
    },
    {
        path: "/ml-operations/:auuid/traced-pensions/:tpuuid",
        name: "Fabric Translate Extracted Pages",
        component: () =>
            import(
                /* webpackChunkName: "MLTracedPensions" */
                "../views/ml-operations/MLPageReview.vue"
                )
    },

    {
        path: "/ml-operations/:auuid/traced-pensions/:tpuuid/extracted-page/:euuid",
        name: "ML Extracted Pages Entities",
        component: () =>
            import(
                /* webpackChunkName: "MLExtractedPagesEntities" */ "../views/ml-operations/MLExtractedPagesEntities.vue"
                )
    },

    {
        path: "/pension-report",
        name: "Pension Report",
        component: () =>
            import(
                /* webpackChunkName: "AggregationReport" */
                "../views/aggregation-report/AggregationReport.vue"
                )
    },
    {
        path: "/pension-report/:uuid?/traced-pension-list",
        name: "Aggregation Traced Pensions List",
        component: () =>
            import(
                /* webpackChunkName: "AggregationTracedPensionList" */
                "../views/aggregation-report/AggregationTracedPensionList.vue"
                )
    },
    {
        path:
            "/pension-report/:uuid?/traced-pension-list/:tpuuid?/aggregation-form",
        name: "Pension Form",
        component: () =>
            import(
                /* webpackChunkName: "AggregationForm" */
                "../views/aggregation-report/AggregationForm.vue"
                )
    },
    {
        path: "/pension-review/:tpuuid?",
        name: "Pension Review",
        component: () =>
            import(
                /* webpackChunkName: "AggregationReview" */
                "../views/aggregation-report/AggregationReview.vue"
                )
    },
    {
        path: "/file-upload",
        name: "File Upload",
        component: () =>
            import(/* webpackChunkName: "FileUpload" */ "../views/FileUpload.vue")
    },
    {
        path: "/mi-reporting",
        name: "MI Reporting",
        component: () => import(/* webpackChunkName: "MI" */ "../views/MI.vue")
    },
    {
        path: "/pension-providers",
        name: "Pension Providers",
        component: () =>
            import(
                /* webpackChunkName: "PensionProviders" */ "../views/PensionProviders.vue"
                )
    },
    {
        path: "/logout",
        name: "Logout",
        component: () =>
            import(/* webpackChunkName: "Logout" */ "../views/Logout.vue")
    },
    {
        path: "/duplication-manager/:auuid/:type",
        name: "Duplication Manager",
        component: () =>
            import(
                /* webpackChunkName: "DuplicationManager" */ "../views/DuplicationManager.vue"
                )
    },
    {
        path: "/email-manager",
        name: "Email Manager",
        component: () =>
            import(
                /* webpackChunkName: "EmailManager" */ "../views/email-manager/EmailManager.vue"
                )
    },
    {
        path: "/email-manager/email-rule-manager",
        name: "Email Scheduler",
        component: () =>
            import(
                /* webpackChunkName: "EmailScheduler" */ "../views/email-manager/EmailScheduler.vue"
                )
    },
    {
        path: "/ml-operations/rag-rule-manager",
        name: "Rag Rule Manager",
        component: () =>
            import(
                /* webpackChunkName: "RAGRuleManager" */ "../views/ml-operations/RAGRuleManager.vue"
                )
    },
    {
        path: "/app-trace-rules",
        name: "App/Trace Rules",
        component: () =>
            import(/* webpackChunkName: "AppTraceRule" */ "../views/TraceRule.vue")
    },
    {
        path: "/email-thread-viewer/:uuid",
        name: "Email Thread Viewer",
        component: () =>
            import(
                /* webpackChunkName: "EmailThreadViewer" */  "../views/email-manager/EmailThreadViewer.vue"
                )
    },
    {
        path: "/email-send-interface/:tpuuid?",
        name: "Email Send Interface",
        component: () =>
            import(
                /* webpackChunkName: "EmailSendInterface" */ "../views/email-manager/EmailSendInterface.vue"
                )
    },
    {
        path: "/activity-log",
        name: "Activity Log",
        component: () =>
            import(
                /* webpackChunkName: "ActvityView" */ "../views/ActvityView.vue"
                )
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach(async (to, from, next) => {
    const canAccess = await canUserAccess();

    if (to.path !== "/login" && !canAccess.state) {
        next({
            name: "Login"
        });
    } else if (to.path === "/login" && canAccess.state) {
        next({
            name: "Home"
        });
    } else {
        next();
    }
});

export default router;
