<template>
  <label>
    <input
      ref="checkboxValue"
      :type="type"
      :name="name"
      :value="value"
      :checked="checkedVal == value"
      v-model="preSelected"
      @click="handleChecked"
    />
    &nbsp;&nbsp;{{ label }}
  </label>
</template>

<script>
export default {
  name: "CheckInput",
  props: {
    checkedVal: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: "checkbox"
    },
    value: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ""
    },
    successMessage: {
      type: String,
      default: ""
    }
  },
  emits: ["error-update", "updated"],
  data: () => ({
    preSelected: "explicit"
  }),
  watch: {
    value(n) {
      if (!n) return;
      this.inputValue = n;
    },
    inputValue() {
      this.$emit("updated", this.inputValue);
    }
  },
  computed: {
    setInputStyle() {
      return {
        "input-title": this.name == "title",
        "input-name": this.name == "first_name" || this.name == "last_name",
        "input-postcode": this.name == "postcode"
      };
    }
  },
  methods: {
    handleChecked() {
      let value = null;
      if (this.type == "radio") {
        value = this.value;
      } else {
        value = this.$refs.checkboxValue.checked;
      }
      this.$emit("updated", {
        [this.name]: value
      });
    }
  },
  mounted() {
    this.preSelected = this.checkedVal;
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/setup/variables";
label {
  margin-bottom: $spacingBase;
  cursor: pointer;
}
</style>
