export default {
  getDuplicates: state => state.applicationManagement.duplicates,
  getUnassignedApplications: state =>
    state.applicationManagement.unassignedApplications,
  getAssignedApplications: state =>
    state.applicationManagement.assignedApplications,
  getAssignedApplicationsTracedPensions: state => {
    return [].concat.apply(
      [],
      state.applicationManagement?.assignedApplications?.map(app => {
        app.traced_pension?.map(tp => {
          tp.app_uuid = app.uuid;
          return tp;
        });
        return app.traced_pension;
      })
    );
  }
};
