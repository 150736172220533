import { axiosRequest } from "@/store/actions/abstract/axiosRequest";

export default {
    async fetchActivityLogs({ getters, commit }, params = { per_page: 10, page: 1 }) {
        try {
            console.log(params);
            const response = await axiosRequest(
                `${getters.getApiUrl}/${getters.getApiVersion}/activity-log`,
                "post",
                localStorage.getItem("token"),
                params
            );
            commit('setActivityLogs', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching activity logs:', error);
            throw error;
        }
    },

    async fetchActivityLog({ getters, commit }, id) {
        try {
            const response = await axiosRequest(
                `${getters.getApiUrl}/${getters.getApiVersion}/activity-log/${id}`,
                "get",
                localStorage.getItem("token")
            );
            commit('setCurrentActivityLog', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching activity log:', error);
            throw error;
        }
    },

    async filterActivityLogs({ getters, commit }, { filters, per_page = 10, page = 1 }) {
        try {
            const response = await axiosRequest(
                `${getters.getApiUrl}/${getters.getApiVersion}/activity-log/filter`,
                "post",
                localStorage.getItem("token"),
                { ...filters, per_page, page }
            );
            commit('setActivityLogs', response.data);
            return response.data;
        } catch (error) {
            console.error('Error filtering activity logs:', error);
            throw error;
        }
    },

    async searchActivityLogs({ getters, commit }, { param, per_page = 10, page = 1 }) {
        try {
            const response = await axiosRequest(
                `${getters.getApiUrl}/${getters.getApiVersion}/activity-log/search/${param}`,
                "post",
                localStorage.getItem("token"),
                { per_page, page }
            );
            commit('setActivityLogs', response.data);
            return response.data;
        } catch (error) {
            console.error('Error searching activity logs:', error);
            throw error;
        }
    },

    async fetchApplicationUuid({ getters }, subjectId) {
        try {
            const response = await axiosRequest(
                `${getters.getApiUrl}/${getters.getApiVersion}/activity-log/applications/${subjectId}/uuid`,
                "get",
                localStorage.getItem("token")
            );
            return response.data.uuid;
        } catch (error) {
            console.error('Error fetching application UUID:', error);
            throw error;
        }
    },

    async fetchTracedPensionUuid({ getters }, subjectId) {
        try {
            const response = await axiosRequest(
                `${getters.getApiUrl}/${getters.getApiVersion}/activity-log/traced-pensions/${subjectId}/uuid`,
                "get",
                localStorage.getItem("token")
            );
            return response.data.uuid;
        } catch (error) {
            console.error('Error fetching traced pension UUID:', error);
            throw error;
        }
    },

    async fetchTracedPensionUuids({ getters }, subjectId) {
        try {
            const response = await axiosRequest(
                `${getters.getApiUrl}/${getters.getApiVersion}/activity-log/traced-pensions/${subjectId}/uuids`,
                "get",
                localStorage.getItem("token")
            );
            return {
                applicationUuid: response.data.application_uuid,
                tracedPensionUuid: response.data.traced_pension_uuid
            };
        } catch (error) {
            console.error('Error fetching traced pension UUIDs:', error);
            throw error;
        }
    }
};
