export default {
    setAttachments(state, params) {
        state.emailManagerState.attachments = params;
    },
    setAllAccounts(state, params) {
        state.emailManagerState.allAccounts = params;
    },
    setEmailResponse(state, params) {
        state.emailManagerState.emailResponse = params;
    },
    setAccountEmail(state, params) {
        state.emailManagerState.accountEmail = params;
    },
    setUnassignedEmailCount(state, params) {
        state.emailManagerState.unassignedEmailCount = params;
    },
};
