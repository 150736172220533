import {axiosRequest} from "@/store/actions/abstract/axiosRequest";

export default {
    // async getFabricFinderData({getters}, params) {
    //     return await axiosRequest(
    //         `${getters.getApiUrl}/${getters.getApiVersion}/employment/${params.euuid}/fabric-finder/`,
    //         "get",
    //         getters.getToken
    //     );
    // },
    // async postFabricFinderV2Data({getters}, params) {
    //     return await axiosRequest(
    //         `${getters.getFinderV2Url}/api/traces`,
    //         "post",
    //         getters.getToken,
    //         params
    //     );
    // },
    // async getFabricFinderV2Data({getters}, params) {
    //     return await axiosRequest(
    //         // `${getters.getFinderV2Url}/api/traces/${params.tpid}?token=${getters.getToken}`,
    //         `${getters.getFinderV2Url}/api/traces/${params.tpuuid}/suggestions`,
    //         // `${getters.getFinderV2Url}/api/traces/bf92341b-4f47-4e2a-8c13-9faa01b1a5cf/suggestions`,
    //         "get",
    //         getters.getToken
    //     );
    // },
    // async attachProvider({getters}, params) {
    //     return await axiosRequest(
    //         `${getters.getApiUrl}/${getters.getApiVersion}/employment/attach-provider/`,
    //         "post",
    //         getters.getToken,
    //         params
    //     );
    // },
    // async quickProviderAssign({getters}, params) {
    //     return await axiosRequest(
    //         `${getters.getApiUrl}/${getters.getApiVersion}/employment/quick-provider-assign/`,
    //         "post",
    //         getters.getToken,
    //         params
    //     );
    // },
    // async fetchFinderEmployerStatus({getters, commit}) {
    //     if (typeof getters.getFinderEmployerStatus(0) === 'undefined') {
    //         let response = await axiosRequest(
    //             `${getters.getFinderV2Url}/api/employers/lookup/status`,
    //             "get",
    //             getters.getToken
    //         );
    //
    //         commit('setFinderEmployerStatus', response.data);
    //     }
    // },
};
