<template>
  <v-main-layout>
    <template #sidebar>
      <v-menu/>
    </template>
    <template #main>
      <router-view v-slot="{ Component }">
        <transition
            :css="false"
            mode="out-in"
            @enter="enterNav"
            @leave="leaveNav"
            @before-enter="beforeNavEnter"
            @before-leave="beforeNavLeave"
        >
          <component :is="Component"/>
        </transition>
      </router-view>
    </template>
  </v-main-layout>
</template>

<script type="text/javascript">
import VMainLayout from "@/components/layout/MainLayout.vue";
import VMenu from "@/components/menu/Menu.vue";
import {watchViewport} from "@/helpers/viewportWatcher";
import {mapActions, mapMutations, mapGetters} from "vuex";

export default {
  components: {
    VMainLayout,
    VMenu
  },
  methods: {
    ...mapMutations([
      "setBreakpoint",
      "setApplicationStatus",
      "setTraceStatus",
      "setProviderList",
      "setClosedConditions"
    ]),
    ...mapActions([
      "fetchProviderList",
      "fetchStatusList",
      "fetchUserPermissions",
    ]),
  },
  computed: {
    ...mapGetters([
      "getToken",
      "getUserPermissions",
    ])
  },
  beforeMount() {
    this.loadStatusLists();
  },
  async mounted() {
    new watchViewport(size => {
      this.setBreakpoint(size);
    });
    await this.fetchUserPermissions();
  }
};
</script>

<style lang="scss">
#app {
}
</style>
