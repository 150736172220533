<template>
  <transition name="fade">
    <div
        :class="['notification', notoficationStyle]"
        v-if="showNotification"
        @click="handleClick"
    >
      <div class="row">
        <div
            class="col-auto d-flex flex-row align-items-start justify-content-start"
        >
          <icon
              :data="getNotificationType == 'success' ? checkCircle : close"
              width="20"
              height="20"
              dir="up"
              color="#fff"
          />
        </div>
        <div class="col-auto pl-0">
          <p class="text-left m-0">
            <strong>{{ getNotificationMessage }}</strong>
          </p>
          <p
              v-if="getNotificationContent"
              v-html="getNotificationContent"
              class="text-left m-0"
          ></p>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import checkCircle from "@fa/solid/check-circle.svg";
import close from "@fa/solid/window-close.svg";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "Notification",
  data: () => ({
    checkCircle,
    close
  }),
  watch: {
    showNotification() {
      setTimeout(
          () => this.toggleNotification(false),
          this.getNotificationDuration || 3000
      );
    }
  },
  computed: {
    ...mapGetters([
      "showNotification",
      "getNotificationType",
      "getNotificationDuration",
      "getNotificationMessage",
      "getNotificationContent"
    ]),
    notoficationStyle() {
      return {
        "-red": this.getNotificationType == "error",
        "-green": this.getNotificationType == "success"
      };
    }
  },
  methods: {
    ...mapMutations(["toggleNotification"]),
    handleClick() {
      this.toggleNotification(false);
    }
  }
};
</script>
<style lang="scss" scoped>
.notification {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  right: 20px;
  top: 20px;
  min-width: 250px;
  height: auto !important;
  color: white;
  padding: calc($spacingBase / 2);
  border-radius: 0.25rem;
  margin: 0;
  cursor: pointer;
  z-index: 2999;

  &.-red {
    background: $red;
  }

  &.-green {
    background: $successGreen;
  }

  h5,
  p {
    color: white;
    margin: 0 0 0 10px;
  }

  p {
    margin: 0;
    margin-left: $spacingBase * 1.5;
  }
}
</style>
