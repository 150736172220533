<template>
  <li :title="linkName" class="nav-item">
    <slot name="alert"></slot>
    <span :class="[
        'nav-link link-menu-item text-left -no-underline',
        {
          active: isActive,
          'link-logout': isLogout(getLinkName(linkName))
        }
      ]"
          class="pointer"
          @click="externalLink?openExternalLink():goToPage()"
    ><icon :data="iconSrc" :height="$parent.iconHeight" :width="$parent.iconWidth" dir="up"/>{{
        getCollapsed ? "" : "&nbsp;&nbsp;" + getLinkName(linkName)
      }}
    </span>
  </li>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "MenuOption",
  props: {
    linkName: {
      default: null
    },
    iconSrc: {
      type: Object,
      default: () => ({})
    },
    externalLink: {
      type: String,
      default: null
    },
    externalLinkNewTab: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(["getCollapsed"]),
    isActive() {
      if (Array.isArray(this.linkName)) {
        return this.linkName.includes(this.$route.name);
      } else {
        return this.linkName == this.$route.name;
      }
    }
  },
  methods: {
    getLinkName(linkName) {

      if (Array.isArray(linkName)) {
        const name =
            linkName.filter(l => l == this.$route.name)[0] || linkName[0];
        return name;
      } else {
        return linkName;
      }
    },
    isLogout(route) {
      return route == "Logout";
    },
    openExternalLink() {
      window.open(this.externalLink, this.externalLinkNewTab ? '_blank' : '_self')
    },
    goToPage() {
      this.$router.push({
        name: this.getLinkName(this.linkName),
      });
    }
  }
};
</script>
