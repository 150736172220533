<template>
  <div
    class="TextInput"
    :class="[
      setInputStyle,
      {
        'has-error': !!errorMessage,
        success: meta.valid
      }
    ]"
  >
    <label :for="name">{{ label }}</label>
    <textarea
      :name="name"
      :id="name"
      :rows="rows"
      cols="80"
      v-model="inputValue"
      @input="handleChange"
      @blur="handleBlur"
    ></textarea>
    <p class="help-message" v-show="errorMessage || meta.valid">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>

<script>
import { useField } from "vee-validate";
import { watch } from "vue";
export default {
  name: "TextArea",
  props: {
    value: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    rows: {
      type: String,
      default: "8"
    },
    successMessage: {
      type: String,
      default: ""
    }
  },
  emits: ["error-update", "updated"],
  setup(props, { emit }) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta
    } = useField(props.name, undefined, {
      initialValue: props.value
    });

    watch(meta, () => {
      emit("error-update", meta.valid);
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta
    };
  },
  watch: {
    value(n) {
      if (!n) return;
      this.inputValue = n;
    },
    inputValue() {
      this.$emit("updated", this.inputValue);
    }
  },
  computed: {
    setInputStyle() {
      return {
        "input-title": this.name == "title",
        "input-name": this.name == "first_name" || this.name == "last_name",
        "input-postcode": this.name == "postcode"
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/setup/variables";

.TextInput {
  position: relative;
  margin-bottom: calc(1em * 1.5);
  width: 100%;
}

label {
  display: block;
  margin-bottom: 4px;
  width: 100%;
}

textarea {
  border-radius: 5px;
  border: 2px solid transparent;
  padding: 15px 10px;
  outline: none;
  background-color: $inputBackground;
  width: 100%;
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
}

textarea:focus {
  border-color: var(--primary-color);
}

.help-message {
  position: absolute;
  bottom: calc(-1.5 * 1em);
  left: 0;
  margin: 0;
  font-size: 14px;
}

.TextInput.has-error textarea {
  background-color: var(--error-bg-color);
  color: var(--error-color);
}

.TextInput.has-error textarea:focus {
  border-color: var(--error-color);
}

.TextInput.has-error .help-message {
  color: var(--error-color);
}

.TextInput.success textarea {
  background-color: var(--success-bg-color);
  color: var(--success-color);
}

.TextInput.success textarea:focus {
  border-color: var(--success-color);
}

.TextInput.success .help-message {
  color: var(--success-color);
}
</style>
