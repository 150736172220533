<template>
  <div class="email-alert" v-if="emailCount > 0">
    <span>{{ emailCount }}</span>
  </div>
</template>
<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: "EmailAlert",
  data: () => ({
    emailResponse: null
  }),
  computed: {
    ...mapGetters([
        "getUnassignedEmailCount"
    ]),
    emailCount() {
      return this.getUnassignedEmailCount?.data?.total;
    }
  },
  methods: {
    ...mapActions([
        "fetchUnnassignedEmails"
    ])
  },
  async beforeMount() {
    await this.loadUnnassignedEmails();
  }
}
</script>
<style lang="scss">
.email-alert {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 7px;
  right: 2px;
  width: auto;
  height: 20px;
  padding:5px;
  border-radius: 5px;
  background-color: $brandRed;
  color: white;
  pointer-events: none;
  z-index: 10;

  span {
    display: contents;
    font-size:12px;
    font-weight: bold;
  }
}
</style>