export default {
  setProviderAssociation(state, value) {
    state.association.provider = value;
  },
  setSchemeAssociation(state, value) {
    state.association.scheme = value;
  },
  clearProviderAssociation(state) {
    state.association.provider = null;
  },
  clearSchemeAssociation(state) {
    state.association.scheme = null;
  },
  setPensionSchemes(state) {
    state.association.children = null;
  },
  setProviderUpdatedData(state, params) {
    state.association.providerUpdatedData = params;
  },
  setSchemeUpdatedData(state, params) {
    state.association.schemeUpdatedData = params;
  },
  clearProviderUpdatedData(state) {
    state.association.providerUpdatedData = {
      associationProviderId: null,
      name: null,
      userSuppliedProvider: null,
      providerAddress: null,
      pensionProviderEmail: null,
      communicationByPost: null,
      requiresWetSignature: null,
      requiresEncryption: null,
      responseGoesToCustomer: null
    };
  },
  clearSchemeUpdatedData(state) {
    state.association.schemeUpdatedData = {
      associationProviderId: null,
      name: null,
      userSuppliedProvider: null,
      providerAddress: null,
      pensionProviderEmail: null,
      communicationByPost: null,
      requiresWetSignature: null,
      requiresEncryption: null,
      responseGoesToCustomer: null
    };
  }
};
