import {axiosRequest} from "@/store/actions/abstract/axiosRequest";

export default {
    async fetchUserPermissions({getters, commit}) {
        let response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/get-user-permissions`,
            "get",
            localStorage.getItem("token")
        );

        commit("setUserPermissions", response.data);

        return response;
    },
    async getLoggedInUser({getters, commit}) {
        let response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/get-logged-in-user`,
            "get",
            localStorage.getItem("token")
        );
        commit("setUser", response.data);
        return response;
    }
};
