<template>
  <div
      :class="[
      setInputStyle,
      {
        'has-error': !!errorMessage,
        success: meta.valid
      }
    ]"
      class="TextInput"
  >
    <label v-if="label" :for="name">{{ label }}</label>
    <input
        :id="name"
        :ref="name"
        :name="name"
        :placeholder="placeholder"
        :type="type"
        :value="inputValue"
        @blur="handleBlur"
        @input="handleChange"
    />
    <p v-show="errorMessage || meta.valid" class="help-message">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>

<script>
import {useField} from "vee-validate";
import {watch} from "vue";

export default {
  name: "TextInput",
  props: {
    type: {
      type: String,
      default: "text"
    },
    value: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: [String, undefined],
      required: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    successMessage: {
      type: String,
      default: ""
    }
  },
  emits: ["error-update", "updated"],
  setup(props, {emit}) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta
    } = useField(props.name, undefined, {
      initialValue: props.value
    });

    watch(meta, () => {
      emit("error-update", meta.valid);
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta
    };
  },
  watch: {
    value(n) {
      if (!n) return;
      this.inputValue = n;
    },
    inputValue() {
      this.$emit("updated", this.inputValue);
    }
  },
  computed: {
    setInputStyle() {
      return {
        "input-title": this.name == "title",
        "input-name": this.name == "first_name" || this.name == "last_name",
        "input-postcode": this.name == "postcode"
      };
    }
  },
  methods: {
    clear() {
      this.inputValue = "";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/setup/variables";

.TextInput {
  position: relative;
  margin-bottom: calc(1em * 1.5);
  width: 100%;
}

label {
  display: block;
  margin-bottom: 4px;
  width: 100%;
}

input {
  border-radius: 5px;
  border: 2px solid transparent;
  padding: 15px 10px;
  outline: none;
  background-color: $inputBackground;
  width: 100%;
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
  background-color 0.3s ease-in-out;
}

input:focus {
  border-color: var(--primary-color);
}

.help-message {
  position: absolute;
  bottom: calc(-1.5 * 1em);
  left: 0;
  margin: 0;
  font-size: 14px;
}

.TextInput.has-error input {
  background-color: var(--error-bg-color);
  color: var(--error-color);
}

.TextInput.has-error input:focus {
  border-color: var(--error-color);
}

.TextInput.has-error .help-message {
  color: var(--error-color);
}

.TextInput.success input {
  background-color: var(--success-bg-color);
  color: var(--success-color);
}

.TextInput.success input:focus {
  border-color: var(--success-color);
}

.TextInput.success .help-message {
  color: var(--success-color);
}
</style>
