import gsap from "gsap";

export default {
  methods: {
    beforeNavEnter(el) {
      gsap.set(el, {
        css: { opacity: "0" },
        ease: "power3.inOut"
      });
    },
    enterNav(el, done) {
      gsap.to(el, {
        duration: 0.3,
        css: { opacity: "1" },
        ease: "power3.inOut",
        onComplete: () => {
          done();
        }
      });
    },
    beforeNavLeave() {},
    leaveNav(el, done) {
      gsap.to(el, {
        duration: 0.3,
        css: { opacity: "0" },
        ease: "power3.inOut",
        onComplete: () => {
          done();
        }
      });
    }
  }
};
