export default {
    global: {
        api: process.env.VUE_APP_API_URL,
        finderV2Url: process.env.VUE_APP_FINDER_V2_URL,
        version: "api/v1",
        token: null,
        loading: false,
        loadingMessage: "Loading...",
        breakpoint: false,
        pusherUpdate: false,
        user: null,
        userPermissions: null
    }
};
