<template>
  <div
    :class="[
      'DatePicker',
      {
        'has-error': !!errorMessage,
        success: meta.valid
      }
    ]"
  >
    <label :for="name" v-if="label">{{ label }}</label>
    <Datepicker
      ref="datepicker"
      inputFormat="dd/MM/yyyy"
      v-model="inputValue"
      @input="handleUpdate"
      @blur="handleBlur"
      :clearable="true"
      :typeable="true"
      :readonly="false"
      :class="[
        {
          'has-error': !!errorMessage,
          success: meta.valid
        }
      ]"
    />
    <input
      :name="name"
      :id="name"
      type="hidden"
      :value="inputValue"
      :placeholder="placeholder"
    />
    <p class="help-message" v-show="errorMessage || meta.valid">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>
<script>
import Datepicker from "vue3-datepicker";
import { useField } from "vee-validate";
import { watch } from "vue";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

export default {
  name: "DatePicker",
  props: {
    label: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false
    },
    value: {
      type: Date,
      default: null
    },
    placeholder: {
      type: String,
      default: ""
    },
    successMessage: {
      type: String,
      default: ""
    }
  },
  emits: ["error-update", "selected"],
  data: () => ({
    sendUpdate: null
  }),
  setup(props, { emit }) {
    const { value: inputValue, errorMessage, meta } = useField(
      props.name,
      undefined,
      {
        initialValue: props.value
      }
    );

    watch(meta, () => {
      emit("error-update", meta.valid);
    });

    return {
      errorMessage,
      inputValue,
      meta
    };
  },
  watch: {
    value(n) {
      if (!n) return;
      this.inputValue = n;
    },
    inputValue(n) {
      clearTimeout(this.sendUpdate);
      this.sendUpdate = setTimeout(() => {
        this.$emit("selected", n);
      }, 1000);
    }
  },
  components: {
    Datepicker
  },
  methods: {
    handleBlur() {
      var splitDate = this.$refs.datepicker.input
        .split("/")
        .reverse()
        .join("/");
      let date = new Date(splitDate);
      if (date == "Invalid Date") {
        this.inputValue = null;
      } else {
        this.inputValue = date;
      }
    },
    handleUpdate() {
      if (this.$refs.datepicker.input == "") {
        this.inputValue = null;
      }
    }
  },
  beforeUnmount() {
    this.inputValue = null;
  }
};
</script>
<style lang="scss">
@import "@/styles/setup/variables";

.DatePicker {
  margin-bottom: calc(1em * 1.5);
  label {
    margin-bottom: 4px;
  }

  .v3dp__datepicker {
    input[type="text"] {
      border-radius: 5px;
      border: 2px solid transparent;
      padding: 15px 10px;
      outline: none;
      background-color: $inputBackground;
      width: 150px;
      transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;

      &.has-error {
        background-color: var(--error-bg-color);
        color: var(--error-color);
      }

      &.has-error:focus {
        border-color: var(--error-color);
      }

      &.has-error ~ .help-message {
        color: var(--error-color);
      }

      &.success {
        background-color: var(--success-bg-color);
        color: var(--success-color);
      }

      &.success:focus {
        border-color: var(--success-color);
      }

      &.success ~ .help-message {
        color: var(--success-color);
      }
    }

    input:focus {
      border-color: var(--primary-color);
    }

    .help-message {
      position: absolute;
      bottom: calc(-1.5 * 1em);
      left: $spacingBase;
      margin: 0;
      font-size: 14px;
    }
  }
  &.has-error > .help-message {
    color: var(--error-color);
  }
  &.success > .help-message {
    color: var(--success-color);
  }
}
</style>
