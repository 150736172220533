export default {

    setApplicationUuid(state, value) {
        if (typeof state.application.data === 'undefined') {
            state.application.data = {
                uuid: null
            };
        }
        state.application.data.uuid = value;
    },
    setTracedPensionUuid(state, value) {
        if (typeof state.tracedPension.data === 'undefined') {
            state.tracedPension.data = {
                uuid: null
            };
        }
        state.tracedPension.data.uuid = value;
    },
    setEmployments(state, value) {
        state.employments.data = value;
    },
    setProviderList(state, value) {
        state.application.providerList = value;
    },
    setEmploymentList(state, value) {
        state.application.employmentList = value;
    },
    setProvidersDataTree(state, value) {
        state.application.providersDataTree = value;
    },
    setProvidersHashTable(state, value) {
        state.application.providersHashTable = value;
    },
    setApplication(state, value) {
        state.application.data = value;
    },
    setApplicationTest(state, value) {
        state.application.data.test = value.test;
    },
    setEmailApplication(state, value) {
        state.emailApplication = value;
    },
    setApplications(state, value) {
        state.applications = value;
    },
    setTracedPension(state, value) {
        if (!value) return;
        if (value.length && state.tracedPension.data) {
            let tp = value.filter(
                val => val.uuid === state.tracedPension.data.uuid
            )[0];
            state.tracedPension.data = tp;
        } else {
            state.tracedPension.data = value;
        }
    },
    setAggregationReport(state, value) {
        state.aggregationReport.data = value;
    },
    setFiles(state, value) {
        state.files = value;
    },
    setAggregationReportValue(state, {key, value}) {
        if (key == null || value == null) return;
        Object.assign(state.aggregationReport.data, {[key]: {value: value}});
    },
    setWithSig(state, value) {
        state.withSig = value;
    },
    setNoEmployerInput(state, value) {
        state.noEmployerInput = value;
    },
    setApplicationStatus(state, value) {
        state.statusAppList = value;
    },
    setTraceStatus(state, value) {
        state.statusTPList = value;
    },
    setClosedConditions(state, value) {
        state.statusClosedReason = value;
    },
    setFinderEmployerStatus(state, value) {
        state.finderEmployerStatus = value;
    },
    setFinderStatus(state, bool) {
        state.finderStatus = bool;
    },
    setSourceList(state, value) {
        state.application.sourceList = value;
    },
};
