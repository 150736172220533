<template>
  <div :id="id" :class="['scroller-wrap', constrainHeightStyle]">
    <div>
      <slot></slot>
    </div>
    <div v-if="loading" class="mt-3">
      <LoadingSpinnerIcon
          :width="50"
      />
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";
import LoadingSpinnerIcon from "@/components/loader/LoadingSpinnerIcon";

export default {
  name: "ScrollerWrap",
  props: {
    next: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    constrainHeight: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update-list"],
  data: () => ({
    allowTrigger: true,
    loading: false,
  }),
  components: {
    LoadingSpinnerIcon,
  },
  computed: {
    constrainHeightStyle() {
      return {
        '-constrain-height': this.constrainHeight,
        '-bottom-padding': !this.constrainHeight,
      }
    }
  },
  methods: {
    ...mapActions([
      "lazyLoad"
    ]),
    getNextUser() {
      let ele = document.getElementById(this.id);
      ele?.removeEventListener("scroll", this.handleScroll, false);
      this.$nextTick(() => {
        ele?.addEventListener("scroll", this.handleScroll, false);
      });
    },
    async handleScroll() {
      let scrollTop = document.getElementById(this.id).scrollTop;
      let offsetHeight = document.querySelector("#" + this.id + " > div")
          .offsetHeight;
      let clientHeight = document.getElementById(this.id)
          .clientHeight;
      if ((scrollTop + clientHeight) > offsetHeight) {
        if (this.next === null) return;
        if (!this.allowTrigger) return;
        this.allowTrigger = false;
        this.loading = true;
        let response = await this.lazyLoad(this.next).catch(err => console.log(err));
        if (response?.data) {
          this.$emit('update-list', response?.data);
          this.allowTrigger = true;
          this.loading = false;
        }
      }
    },
  },
  mounted() {
    this.getNextUser();
  }
};
</script>
<style lang="scss" scoped>
.scroller-wrap {
  height: 60vh;
  overflow: hidden;
  overflow-y: scroll;


  &.-bottom-padding {
    padding-bottom: 20px;
  }

  &.-bottom-padding-large {
    padding-bottom: 40px;
  }

  &.-constrain-height {
    height: auto;
    max-height: 300px;
  }

  $sizes: 20, 30, 40, 50, 60, 70, 80, 90, 150;

  @each $size in $sizes {
    &.-bottom-padding-#{$size} {
      padding-bottom: #{$size}px;
    }
  }

  @each $size in $sizes {
    &.-height-#{$size} {
      height: #{$size}vh;
    }
  }

  &.-height-100-perc {
    height: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    pointer-events: none;
    left: 0;
    bottom: 14px;
    width: 100%;
    height: 50px;
    background: linear-gradient(to bottom, transparent, white);
    z-index: 10;
  }
}
</style>
