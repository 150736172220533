export default {
  setExtractedText(state, param) {
    state.mlState.applicationExtractedText = param;
  },
  setDocumentSrc(state, value) {
    state.mlState.documentSrc = value;
  },
  setExtractedTextContent(state, value) {
    state.mlState.textContent = value;
  },
  setPDFPage(state, value) {
    state.mlState.pdfPage = value;
  },
  setPDFArray(state, value) {
    state.mlState.pdfArray = value;
  },
  setExtractedTextArray(state, param) {
    state.mlState.extractedTextArray = param;
  },
  setExtractedTextObject(state, param) {
    state.mlState.extractedText = param;
  },
  setEmptyValueArray(state, param) {
    state.mlState.emptyValueArray = param;
  },
  setMLTextEntities(state, param) {
    state.mlState.mLTextEntities = param;
  },
  setMLTextSearchEntities(state, param) {
    state.mlState.mLTextSearchEntities.push(param);
  },
  clearMLTextSearchEntities(state) {
    state.mlState.mLTextSearchEntities = [];
  },
  clearMLTextSearchBlocks(state) {
    state.mlState.mLTextSearchBlocks = [];
  },
  setMLPages(state, param) {
    let count = 0;
    for (let i in param) {
      if (param[i].blockType == "PAGE") {
        count++;
      }
    }
    state.mlState.pdfPageCount = count;
  },
  setMLTextBlocks(state, param) {
    state.mlState.mLTextBlocks = param;
  },
  setMLTextSearchBlocks(state, param) {
    state.mlState.mLTextSearchBlocks.push(param);
  },
  setMLTextUuid(state, value) {
    state.mlState.mLTextUuid = value;
  },
  setMLSelectedTextEntities(state, { entity, ind, shiftDown, batch }) {
    if (shiftDown) {
      state.mlState.mLSelectedTextEntities = null;
      state.mlState.mLSelectedTextEntitiesIndex = null;
      if (batch) {
        state.mlState.mLSelectedTextEntitiesIndexArr = ind;
      } else {
        state.mlState.mLSelectedTextEntitiesArr.push(entity);
        state.mlState.mLSelectedTextEntitiesIndexArr.push(ind);
      }
    } else {
      state.mlState.mLSelectedTextEntitiesArr = [];
      state.mlState.mLSelectedTextEntitiesIndexArr = [];
      state.mlState.mLSelectedTextEntitiesArr.push(entity);
      state.mlState.mLSelectedTextEntitiesIndexArr.push(ind);
      state.mlState.mLSelectedTextEntities = entity;
      state.mlState.mLSelectedTextEntitiesIndex = ind;
    }
  },
  clearMLSelectedTextEntities(state) {
    state.mlState.mLSelectedTextEntities = null;
    state.mlState.mLSelectedTextEntitiesIndex = null;
    state.mlState.mLSelectedTextEntitiesArr = [];
    state.mlState.mLSelectedTextEntitiesIndexArr = [];
  },
  pushMLSelectedTextEntities(state, ind) {
    state.mlState.mLSelectedTextEntitiesIndexArr.push(ind);
  },
  setMLPage(state, value) {
    state.mlState.mLPage = value;
  },
  setMLEditEntity(state, bool) {
    state.mlState.mlEditEntity = bool;
  },
  deactivateEditEntities(state) {
    state.mlState.mlDeactivate = !state.mlState.mlDeactivate;
  },
  storeMLEditSelection(state, value) {
    state.mlState.mlEditSelection = value;
  },
  storeMLProcessedSelection(state, value) {
    state.mlState.mlProcessedSelection = value;
  },
  updateSelecetedEntityIdList(state, value) {
    state.mlState.entitySelectedIdList = value;
  },
  updateSelecetedEntityText(state, value) {
    state.mlState.entitySelectedText = value;
  },
  clearSelecetedEntity(state) {
    state.mlState.entitySelectedIdList = [];
    state.mlState.entitySelectedText = null;
  },
  setMlInformationSource(state, object) {
    Object.assign(state.mlState.mlInformationSource, object);
  },
  setMlRuleType(state, value) {
    state.mlState.ruleType = value;
  },
  setRagEntityRules(state, value) {
    state.mlState.ragEntityRules = value;
  },
  setIsEntityUpdating(state, value) {
    state.mlState.isEntityUpdating = value
  },
};
