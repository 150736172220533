import {axiosRequest} from "@/store/actions/abstract/axiosRequest";

export default {
    async handleLogout({commit}) {
        commit("setToken", null);
        return true;
    },
    async handleLogin({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/oauth/token`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async loginSuccess({commit}, response) {
        localStorage.setItem("token", response.data.access_token);
        commit("setToken", response.data.access_token);
        return true;
    },
    async getUserPermissions({getters}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/get-user-permissions`,
            "post",
            localStorage.getItem("token")
        );
    },
    async decryptFile({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/decrypt-file`,
            "post",
            localStorage.getItem("token"),
            params
        );
    }
};
