<template>
  <div class="pop-over" @mouseover="handleShow" @mouseout="handleHide" @click="handleHide">
    <slot class="ignore_pointer"></slot>
    <div v-if="show && content !== null" class="ignore_pointer pop-text mt-2" v-html="replaceNBSpace(content)"></div>
  </div>
</template>
<script>
export default {
  name: "PopOver",
  props: {
    content: {
      type: String,
      default: null
    }
  },
  data: () => ({
    show: false
  }),
  methods: {
    replaceNBSpace(text) {
      return text.replace(' ', '&nbsp;');
    },
    handleShow() {
      this.show = true;
    },
    handleHide() {
      this.show = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.pop-over {
  position: relative;

  &.-width-200 {
    .pop-text {
      width: 200px;
    }
  }

  &.-width-400 {
    .pop-text {
      width: 400px;
    }
  }

  &.-width-600 {
    .pop-text {
      width: 600px;
    }
  }
}

.pop-text {
  position: absolute;
  top: 20px;
  left: 0px;
  width: auto;
  background-color: white;
  border-radius: 3px;
  display: flex;
  padding: $spacingBase;
  box-shadow: 0 0 10px #eee;
  z-index: 10;
}
</style>
