export default {
  association: {
    provider: null,
    scheme: null,
    children: null,
    providerUpdatedData: {
      associationProviderId: null,
      name: null,
      userSuppliedProvider: null,
      providerAddress: null,
      pensionProviderEmail: null,
      pensionProviderContact: null,
      communicationByPost: null,
      requiresWetSignature: null,
      requiresEncryption: null,
      responseGoesToCustomer: null
    },
    schemeUpdatedData: {
      associationProviderId: null,
      name: null,
      userSuppliedProvider: null,
      providerAddress: null,
      pensionProviderEmail: null,
      pensionProviderContact: null,
      communicationByPost: null,
      requiresWetSignature: null,
      requiresEncryption: null,
      responseGoesToCustomer: null
    }
  }
};
