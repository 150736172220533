import axios from "axios";

function setToken(token) {
    localStorage.setItem("token", token);
}

function getToken() {
    return localStorage.getItem("token");
}

export async function canUserAccess(setNewToken = true) {
    const config = {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    };

    const response = await axios
        .post(process.env.VUE_APP_API_URL + "/api/v1/me", null, config)
        .catch(() => false);

    if (!response) {
        return {
            state: false,
            token: null
        };
    }

    if (setNewToken) {
        setToken(response.data.token);
    }

    return {
        state: true,
        token: response.data.token
    };
}
