<template>
  <div class="container-fluid overflow-auto">
    <PusherNotification v-if="$route.name !== 'Login'"/>
    <Loading/>
    <div if="getEnvName" class="position-absolute"
         style="right: 20px; top:10px; z-index: 1000; width: auto; height: auto;">
      <h4 class="badge badge-warning" :title="'You are in the ' + getEnvName + ' environment'">{{ getEnvName }}</h4>
    </div>
    <div class="row">
      <div v-if="$route.name !== 'Login'" :class="['side-bar', sideStyle]">
        <slot name="sidebar"/>
      </div>
      <div :class="['main-content', mainStyle]">
        <slot name="main"/>
      </div>
    </div>
    <Notification/>
  </div>
</template>
<script>
import Loading from "@/components/loader/LoadingSpinner";
import Notification from "@/components/notification/Notification";
import PusherNotification from "@/components/notification/PusherNotification";
import {mapGetters} from "vuex";

export default {
  name: "VMainLayout",
  components: {
    Loading,
    Notification,
    PusherNotification
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["getCollapsed"]),
    getEnvName() {
      return process.env.VUE_APP_ENV_NAME
    },
    sideStyle() {
      return {
        "-collapsed p-0": this.getCollapsed == true,
        "p-0": this.getCollapsed == false
      };
    },
    mainStyle() {
      return {
        "": this.$route.name != "Login",
        "col-sm-12 m-0": this.$route.name == "Login",
        "-collapsed": this.getCollapsed == true
      };
    }
  }
};
</script>
