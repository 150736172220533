export default {
    setToken(state, token) {
        localStorage.setItem("token", token);
    },
    setLoading(state, {bool, message = null}) {
        state.global.loading = bool;
        state.global.loadingMessage = message || "Loading...";
    },
    setBreakpoint(state, value) {
        state.global.breakpoint = value;
    },
    pingPusherNotification(state, value) {
        state.global.pusherUpdate = value;
    },
    setUser(state, value) {
        state.global.user = value;
    },
    setUserPermissions(state, value) {
        state.global.userPermissions = value;
    },
};
