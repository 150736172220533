export default {
    getApplicationUuid: state => state.application.data.uuid,
    getApplicationFullName: state =>
        `${state.application.data.first_name} ${state.application.data.last_name}`,
    getTracedPensionUuid: state => state.tracedPension?.data?.uuid,
    getProviders: state => (excludeSelected = false) => {
        if (!excludeSelected) {
            return state.application.providerList;
        }
        return state.application.providerList.filter(
            i =>
                i.name !== state.association.providerUpdatedData.name &&
                i.name !== state.association.schemeUpdatedData.name
        );
    },
    getFinderEmployers: state => state.application.employmentList,
    getFinderSources: state => state.application.sourceList,
    getFinderStatus: state => state.finderStatus,
    getProvidersDataTree: state => state.application.providersDataTree,
    getProvidersHashTable: state => state.application.providersHashTable,
    getApplication: state => state.application.data,
    getEmailApplication: state => state.emailApplication,
    getApplications: state => state.applications,
    getTracedPension: state => state.tracedPension.data,
    getEmployments: state => state.employments.data,
    getStoredAggregationReport: state => state.aggregationReport.data,
    getStoredAggregationReportValue: state => key => {
        return state.aggregationReport?.data[key]?.value || "";
    },
    getCurrentContact: state =>
        state.application.data.contact.filter(c => c.current === "1")[0] || null,
    getCurrentAddress: state =>
        state.application.data.address.filter(c => c.current === "1")[0] || null,
    getStatusAppValues: state => state.statusAppList,
    getStatusTPValues: state => state.statusTPList,
    getFiles: state => state.files,
    getWithSig: state => state.withSig,
    getNoEmployerInput: state => state.noEmployerInput,
    getFinderEmployerStatus: state => key => state.finderEmployerStatus[key], // TODO: get this working
    getStatusClosedReason: state => state.statusClosedReason
};
