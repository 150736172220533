import "@babel/polyfill";
import "mutationobserver-shim";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./axios-bootstrap";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// components
import Close from "@/components/misc/Close";
import TextArea from "@/components/form/TextArea";
import TextInput from "@/components/form/TextInput";
import CheckInput from "@/components/form/CheckInput";
import AmountInput from "@/components/form/AmountInput";
import SelectInput from "@/components/form/SelectInput";
import DatePicker from "@/components/form/DatePicker";
import EditModal from "@/components/modal/EditModal";
import EditAssociateModal from "@/components/modal/EditAssociateModal";
import PopOver from "@/components/misc/PopOver";
import InfoModal from "@/components/modal/InfoModal";
import BackButton from "@/components/navigation/BackButton";
import BreadCrumb from "@/components/navigation/BreadCrumb";
import ScrollerWrap from "@/components/misc/ScrollerWrap";


import SearchText from "@/components/search/SearchText";
import TitleHeader from "@/components/layout/TitleHeader";

import { Form } from "vee-validate";
import { VueSvgIconPlugin } from "@yzfe/vue3-svgicon";
import "@yzfe/svgicon/lib/svgicon.css";

// mixins
import animationMixin from "@/mixins/animationMixin";
import componentMixins from "@/mixins/componentMixins";

var VueScrollTo = require("vue-scrollto");

const app = createApp(App);

if (process.env.NODE_ENV === "development") {
  window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app;
}

// register global components
app.component("Close", Close);
app.component("TextArea", TextArea);
app.component("TextInput", TextInput);
app.component("CheckInput", CheckInput);
// app.component("CheckInputV2", CheckInputV2);
app.component("AmountInput", AmountInput);
app.component("SelectInput", SelectInput);
app.component("DatePicker", DatePicker);
app.component("Form", Form);
app.component("BackButton", BackButton);
app.component("EditModal", EditModal);
app.component("EditAssociateModal", EditAssociateModal);
app.component("InfoModal", InfoModal);
app.component("PopOver", PopOver);
app.component("SearchText", SearchText);
app.component("TitleHeader", TitleHeader);
app.component("BreadCrumb", BreadCrumb);
app.component("ScrollerWrap", ScrollerWrap);

// global mixins
app.mixin(componentMixins);

app
  .use(VueScrollTo)
  .use(store)
  .use(router)
  .use(VueSvgIconPlugin, { tagName: "icon" })
  .mixin(animationMixin)
  .mount("#app");
