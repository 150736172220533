import {axiosRequest} from "@/store/actions/abstract/axiosRequest";

export default {
    async updateParent({getters}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/get-applications-with-ml-docs`,
            "post",
            localStorage.getItem("token")
        );
    },
    async getProviderData({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/pension-providers/${params.id}`,
            "get",
            localStorage.getItem("token")
        );
    },
    async getProviderChildren({getters}, id) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/pension-providers/${id}/children`,
            "get",
            localStorage.getItem("token")
        );
    },
    async updateProvider({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/pension-providers/${params.provider_id}`,
            "put",
            localStorage.getItem("token"),
            params
        );
    },
    async removeProvider({getters, dispatch}, provider_id) {
        let response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/pension/${getters.getTracedPensionUuid}/remove-provider/${provider_id}`,
            "get",
            localStorage.getItem("token")
        );

        dispatch('getTracedPensionFocus', getters.getTracedPensionUuid);

        return response;
    }
};
