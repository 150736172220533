import {axiosRequest} from "@/store/actions/abstract/axiosRequest";

export default {
    async getFabricFinderData({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/employment/${params.euuid}/fabric-finder/`,
            "get",
            localStorage.getItem("token")
        );
    },
    async postFabricFinderV2Data({getters}, params) {
        return await axiosRequest(
            `${getters.getFinderV2Url}/api/traces`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async getFabricFinderV2Data({getters}, params) {
        return await axiosRequest(
            `${getters.getFinderV2Url}/api/traces/${params.tpuuid}/suggestions`,
            "get",
            localStorage.getItem("token")
        );
    },
    async attachProvider({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/employment/attach-provider/`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async quickProviderAssign({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/employment/quick-provider-assign/`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async fetchFinderEmployerStatus({getters, commit}) {
        if (typeof getters.getFinderEmployerStatus(0) === 'undefined') {
            let response = await axiosRequest(
                `${getters.getFinderV2Url}/api/employers/lookup/status`,
                "get",
                localStorage.getItem("token")
            );

            commit('setFinderEmployerStatus', response.data);
        }
    },
    async fetchFinderEmploymentList({getters, commit}) {
        if (typeof getters.getFinderEmployers !== 'undefined') {
            return true;
        }
        let response = await axiosRequest(
            `${getters.getFinderV2Url}/api/employers/lookup/name`,
            "get",
            localStorage.getItem("token")
        );

        let tempArr = [];
        for (let i in response.data.data) {
            tempArr.push({"id": i, "name": response.data.data[i]});
        }

        commit("setEmploymentList", tempArr);

        return response;
    },
    async fetchFinderSourceList({getters, commit}) {
        if (typeof getters.getFinderSources !== 'undefined') {
            return true;
        }
        let response = await axiosRequest(
            `${getters.getFinderV2Url}/api/sources/lookup/name`,
            "get",
            localStorage.getItem("token")
        );

        let tempArr = [];
        for (let i in response.data.data) {
            tempArr.push({"id": i, "name": response.data.data[i]});
        }

        commit("setSourceList", tempArr);

        return response;
    },
    async createSource({getters}, params) {
        return await axiosRequest(
            `${getters.getFinderV2Url}/api/sources`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async createEmployer({getters}, params) {
        return await axiosRequest(
            `${getters.getFinderV2Url}/api/employers`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
};
