import axios from "axios";
import {
    axiosRequest,
    axiosExternalRequest
} from "@/store/actions/abstract/axiosRequest";

export default {
    async addRagRule({getters}, param) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/rag-entity-rule`,
            "post",
            localStorage.getItem("token"),
            param
        );
    },
    async fetchRagRules({getters}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/rag-entity-rule`,
            "get",
            localStorage.getItem("token"),
        );
    },
    async searchRagRules({getters}, param) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/rag-entity-rule/search`,
            "post",
            localStorage.getItem("token"),
            param
        );
    },
    async deleteRagRule({getters}, id) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/rag-entity-rule/${id}`,
            "delete",
            localStorage.getItem("token"),
        );
    },
    async getApplicationsWithMLDocs({getters}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/get-applications-with-ml-docs`,
            "post",
            localStorage.getItem("token")
        );
    },
    async getTracedPensionWithMLDocs({getters, commit}, tpuuid) {
        let response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/get-traced-pension-with-ml-docs/${tpuuid}`,
            "post",
            localStorage.getItem("token")
        );

        commit("setAggregationReport", response.data.data[0]);

        return response;
    },
    async getExtractedText({getters}, param) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/get-extracted-text-list`,
            "post",
            localStorage.getItem("token"),
            param
        );
    },
    async getAWSFile({getters}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/get-aws-file`,
            "post",
            localStorage.getItem("token"),
            {
                doc: getters.getDocumentSrc
            }
        );
    },
    async removeLocalFile({getters}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/remove-local-file`,
            "post",
            localStorage.getItem("token"),
            {
                doc: getters.getDocumentSrc
            }
        );
    },
    async clearMLData({commit}) {
        commit("setMLPages", null);
        commit("setMLTextBlocks", null);
        commit("setMLTextEntities", null);
        commit("setRagEntityRules", null);
        commit("setPDFArray", null);
    },
    async getMLData({getters, commit}, {tpuuid}) {
        let dataResponse = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/get-ml-data`,
            "post",
            localStorage.getItem("token"),
            {uuid: tpuuid}
        );
        let dataUrlMap = Object.fromEntries(
            dataResponse.data.map(i => [i.type, i.link])
        );
        let pdfUrl = dataUrlMap.lor;
        let resultsUrl = dataUrlMap["ml-results"];
        let mlResults = dataUrlMap["ml-hug-results"];
        let ragEntityRules = dataUrlMap["rag-entity-rules"];

        let response = await axios.get(resultsUrl);
        let updatedData = response.data.Entities;


        //Add ML Hug Results into the mix
        if (mlResults != null) {
            // let regex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
            for (let key in mlResults.answer) {
                if (mlResults.answer[key] === null) continue;
                let result = updatedData.findIndex(obj => {
                    return obj.FieldName === key;
                });
                if (result !== -1) {
                    let dateChecks = [
                        "fund_value_date",
                        "transfer_value_date",
                        "policy_commence_date",
                        "retirement_date"
                    ];
                    if (dateChecks.includes(key)) {
                        mlResults.answer[key] = new Date(
                            mlResults.answer[key]
                        ).toLocaleDateString();
                    }
                    let BlockIds = 'ML Hug';
                    let MazAI = true;
                    // can't simply use regex test here as is quite expensive and skips next test
                    if (typeof mlResults.block_id !== 'undefined' && mlResults.block_id[key] !== null && mlResults.block_id[key] !== 'regex') {
                        BlockIds = [mlResults.block_id[key]];
                    }
                    let newMatch = {Text: mlResults.answer[key], BlockIds, MazAI};
                    updatedData[result].Matches.unshift(newMatch);
                }
            }
        }

        commit("setMLPages", response.data.Blocks);
        commit("setMLTextBlocks", response.data.Blocks);
        commit("setMLTextEntities", updatedData);
        commit("setRagEntityRules", ragEntityRules);

        let pdfArray = [];
        for (const i of Array(getters.getMLPageCount).keys()) {
            pdfArray.push({
                file: `${pdfUrl}#page=${i + 1}&toolbar=0&navpanes=0&scrollbar=0`
            });
        }

        commit("setPDFArray", pdfArray);

        return response;
    },
    async updateEntity({getters}, param) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/update-entity`,
            "post",
            localStorage.getItem("token"),
            param
        );
    },
    async populateReport({getters}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/populate-report`,
            "post",
            localStorage.getItem("token"),
            {
                tpuuid: getters.getTracedPensionUuid
            }
        );
    },
    async dataMapField(_, params) {
        return await axiosExternalRequest(
            process.env.VUE_APP_DOCUMENT_SERVICE_URL + "/validation/",
            "post",
            params
        );
    },
    async sendLearningData({getters}, {tpuuid, entities}) {
        return await axiosExternalRequest(
            process.env.VUE_APP_DOCUMENT_SERVICE_URL +
            "/validation/validated-document/" +
            tpuuid,
            "post",
            {
                Blocks: getters.getAllBlocks,
                Entities: entities
            }
        );
    },
    async updateML({getters, dispatch}, {tpuuid, formatLearningdata}) {
        let tempParamsKeys = Object.keys(getters.getStoredAggregationReport);
        let tempParams = getters.getStoredAggregationReport;
        let newObject = {};

        //Set Transfer Value Date to Fund Value Date at all times
        //Unable to only set if empty/null because if they only
        //update one field and refresh both of these dates get set to todays date
        tempParams.transfer_value_date.value = tempParams.fund_value_date.value;

        for (let i in tempParamsKeys) {
            if (
                tempParamsKeys[i] === "traced_pension_id" ||
                tempParamsKeys[i] === "uuid" ||
                tempParamsKeys[i] === "status"
            ) {
                newObject[tempParamsKeys[i]] = tempParams[tempParamsKeys[i]];
            } else {
                newObject[tempParamsKeys[i]] = {
                    value: tempParams[tempParamsKeys[i]]?.value || null,
                    information_source:
                        getters.getMlInformationSource[tempParamsKeys[i]]
                            ?.information_source || null,
                    notes:
                        getters.getMlInformationSource[tempParamsKeys[i]]?.notes || null
                };
            }
        }

        let response = await dispatch("updateAggregationReport", {
            tpuuid,
            tempParams: newObject
        }).catch(err => console.error(err));

        let mlResponse = await dispatch(
            "sendLearningData",
            formatLearningdata(
                tpuuid,
                tempParams,
                getters.getMLTextEntitiesConst,
                getters.getMlInformationSource
            )
        ).catch(err => console.log(err));

        await Promise.all([mlResponse, response]);
    }
};
