import {axiosRequest} from "@/store/actions/abstract/axiosRequest";

export default {
    async saveTraceRule({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/trace-rule`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },

    async fetchTraceRule({getters}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/trace-rule`,
            "get",
            localStorage.getItem("token")
        );
    },

    async deletedTraceRule({getters}, id) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/trace-rule/${id}`,
            "delete",
            localStorage.getItem("token")
        );
    },

    async searchTraceRules({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/trace-rule/search`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },

    async toggleTraceRuleActive({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/trace-rule/toggle-active`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
};
