export default {
  setUnassignedApplication(state, value) {
    state.applicationManagement.unassignedApplications = value;
  },
  setAssignedApplication(state, value) {
    state.applicationManagement.assignedApplications = value;
  },
  setDuplicates(state, value) {
    state.applicationManagement.duplicates = value;
  }
};
