<template>
  <svg :width="width+'px'" :height="height+'px'"
       preserveAspectRatio="xMidYMid" style="margin: auto; background: transparent; display: block;"
       viewBox="0 0 100 100"
       xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <filter id="ldio-tuizq7kd7q-filter" color-interpolation-filters="sRGB" height="300%" width="300%" x="-100%"
              y="-100%">
        <feGaussianBlur in="SourceGraphic" stdDeviation="2.4000000000000004"></feGaussianBlur>
        <feComponentTransfer result="cutoff">
          <feFuncA tableValues="0 0 0 0 0 0 1 1 1 1 1" type="table"></feFuncA>
        </feComponentTransfer>
      </filter>
    </defs>
    <g filter="url(#ldio-tuizq7kd7q-filter)">
      <g transform="translate(50 50)">
        <g>
          <circle cx="20" cy="0" fill="#f5431c" r="5">
            <animate attributeName="r" begin="-0.25s"
                     dur="4s" keyTimes="0;0.5;1"
                     repeatCount="indefinite"
                     values="3.5999999999999996;8.399999999999999;3.5999999999999996"></animate>
          </circle>
          <animateTransform attributeName="transform" begin="0s" dur="4s" keyTimes="0;1" repeatCount="indefinite"
                            type="rotate" values="0;360"></animateTransform>
        </g>
      </g>
      <g transform="translate(50 50)">
        <g>
          <circle cx="20" cy="0" fill="#ffa500" r="5">
            <animate attributeName="r" begin="-0.2s"
                     dur="2s" keyTimes="0;0.5;1"
                     repeatCount="indefinite"
                     values="3.5999999999999996;8.399999999999999;3.5999999999999996"></animate>
          </circle>
          <animateTransform attributeName="transform" begin="-0.05s" dur="2s" keyTimes="0;1" repeatCount="indefinite"
                            type="rotate" values="0;360"></animateTransform>
        </g>
      </g>
      <g transform="translate(50 50)">
        <g>
          <circle cx="20" cy="0" fill="#2aa7c9" r="5">
            <animate attributeName="r" begin="-0.15s"
                     dur="1.3333333333333333s" keyTimes="0;0.5;1"
                     repeatCount="indefinite"
                     values="3.5999999999999996;8.399999999999999;3.5999999999999996"></animate>
          </circle>
          <animateTransform attributeName="transform" begin="-0.1s" dur="1.3333333333333333s" keyTimes="0;1"
                            repeatCount="indefinite" type="rotate" values="0;360"></animateTransform>
        </g>
      </g>
      <g transform="translate(50 50)">
        <g>
          <circle cx="20" cy="0" fill="#06628d" r="5">
            <animate attributeName="r" begin="-0.1s"
                     dur="1s" keyTimes="0;0.5;1"
                     repeatCount="indefinite"
                     values="3.5999999999999996;8.399999999999999;3.5999999999999996"></animate>
          </circle>
          <animateTransform attributeName="transform" begin="-0.15s" dur="1s" keyTimes="0;1" repeatCount="indefinite"
                            type="rotate" values="0;360"></animateTransform>
        </g>
      </g>
      <g transform="translate(50 50)">
        <g>
          <circle cx="20" cy="0" fill="#3b3b3b" r="5">
            <animate attributeName="r" begin="-0.05s"
                     dur="0.8s" keyTimes="0;0.5;1"
                     repeatCount="indefinite"
                     values="3.5999999999999996;8.399999999999999;3.5999999999999996"></animate>
          </circle>
          <animateTransform attributeName="transform" begin="-0.2s" dur="0.8s" keyTimes="0;1" repeatCount="indefinite"
                            type="rotate" values="0;360"></animateTransform>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "LoadingSpinnerIcon",
  props: {
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 200
    },
  },
};
</script>
