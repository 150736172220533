<template>
  <span :class="['close-btn', alignStyle]" @click="handleClose"
    ><slot>close</slot></span
  >
</template>
<script>
export default {
  name: "Close",
  props: {
    align: {
      default: "left"
    }
  },
  emits: ["close"],
  computed: {
    alignStyle() {
      return {
        "-align-left": this.align == "right",
        "-align-right": this.align == "left"
      };
    }
  },
  methods: {
    handleClose(e) {
      this.$emit("close", e);
    }
  }
};
</script>
<style lang="scss" scoped>
.close-btn {
  position: absolute;
  top: $spacingBase;
  cursor: pointer;
  z-index: 2000;
  text-decoration: underline;
  color: $brandBlue;
  &.-align-right {
    right: $spacingBase;
  }
  &.-align-left {
    left: 0;
  }
}
</style>
