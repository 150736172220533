<template>
  <div class="pusher-notification">
    <transition-group name="fade">
      <div
          v-for="(message, index) in messages"
          :key="index"
          :class="[
          'pusher-notification-content',
          notificationStyle(message.status)
        ]"
      >
        <div class="row">
          <div
              class="col-12 d-flex flex-row align-items-start justify-content-start"
          >
            <icon
                :data="checkCircle"
                color="#fff"
                dir="up"
                height="20"
                width="20"
            />
            <p class="p-0 m-0 ml-2 text-left">
              <strong
                  v-html="messageContent(message.content)"
              ></strong>
            </p>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>
<script>
import {mapMutations, mapGetters} from "vuex";
import Pusher from "pusher-js";
import checkCircle from "@fa/solid/check-circle.svg";

export default {
  name: "PusherNotification",
  data: () => ({
    messages: [],
    checkCircle
  }),
  computed: {
    ...mapGetters(["getPusherNotification", "getUser"])
  },
  methods: {
    ...mapMutations(["pingPusherNotification"]),
    messageContent(message) {
      if (typeof message === "object" && message !== null) {
        return message.content;
      }
      return message;
    },
    notificationStyle(status) {
      return {
        "-green": status === true,
        "-red": status === false
      };
    },
    setupPusher() {
      Pusher.logToConsole = true;

      var pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: "eu"
      });

      var channel = pusher.subscribe("job-channel");

      channel.bind("job-complete", data => {
        this.messages.push(data.params);
        this.pingPusherNotification({
          bool: !this.getPusherNotification,
          type: data?.params?.type,
        });
        setTimeout(() => {
          this.messages.shift();
        }, 5000);
      });
    }
  },
  mounted() {
    if (process.env.VUE_APP_PUSHER_APP_KEY) {
      this.setupPusher();
    }
  }
};
</script>
<style lang="scss" scoped>
.pusher-notification {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  top: 0;
  right: 0;
  border: 0;
  left: 0;
  pointer-events: none;

  &-content {
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 300px;
    height: auto !important;
    color: white;
    padding: calc(#{$spacingBase} / 2);
    border-radius: 0.25rem;
    margin: 0;
    cursor: pointer;
    margin-bottom: calc(#{$spacingBase} / 2);
    bottom: $spacingBase;
    right: $spacingBase;
    z-index: 900;

    &.-red {
      background: $red;
    }

    &.-green {
      background: $successGreen;
    }

    h5 {
      margin: 0;
      padding-left: calc(#{$spacingBase} / 2);
    }
  }
}
</style>
