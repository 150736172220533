<template>
  <div
    :class="['back-button-wrap position-absolute p-2', sideStyle]"
    role="button"
    @click="handleBack"
  >
    <icon
      :data="arrowRight"
      width="30"
      height="30"
      dir="down"
      color="#0066ff"
    />
  </div>
</template>
<script>
import arrowRight from "@fa/solid/arrow-right.svg";
import { mapGetters } from "vuex";
export default {
  name: "BackButton",
  props: {
    to: {
      type: String,
      default: null
    },
    parentNav: {
      type: Boolean,
      default: false
    }
  },
  emits: ["call-nav"],
  data: () => ({
    arrowRight
  }),
  methods: {
    handleBack() {
      if (this.parentNav) {
        this.$emit("call-nav");
      } else if (this.to) {
        this.$router.push({ name: this.to });
      } else {
        this.$router.go(-1);
      }
    }
  },
  computed: {
    ...mapGetters(["getCollapsed"]),
    sideStyle() {
      return {
        "-expanded-menu": this.getCollapsed === false
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.back-button-wrap {
  left: 60px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 0.25rem;
  z-index: 10;
  @include blurFilter();

  &.-expanded-menu {
    left: 220px;
  }
}
</style>
