<template>
  <div
    class="TextInput -amount"
    :class="[
      setInputStyle,
      noLabelSymbol,
      {
        'has-error': !!errorMessage,
        success: meta.valid
      }
    ]"
  >
    <label :for="name">{{ label }}</label>
    <input
      ref="amount"
      :name="name"
      :id="name"
      :type="type"
      :value="inputValue"
      :placeholder="placeholder"
      @input="handleChange"
      @blur="handleBlur"
      @keypress="keypress"
    />
    <p class="help-message" v-show="errorMessage || meta.valid">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>

<script>
import { useField } from "vee-validate";
import { watch } from "vue";

export default {
  name: "TextInput",
  props: {
    type: {
      type: String,
      default: "text"
    },
    initialValue: {
      type: [String, Number],
      default: ""
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ""
    },
    successMessage: {
      type: String,
      default: ""
    }
  },
  emits: ["error-update", "updated"],
  data: () => ({
    sendUpdate: null
  }),
  setup(props, { emit }) {
    const { value: inputValue, errorMessage, handleChange, meta } = useField(
      props.name,
      undefined,
      {
        initialValue: props.initialValue
      }
    );

    watch(meta, () => {
      emit("error-update", meta.valid);
    });

    return {
      handleChange,
      errorMessage,
      inputValue,
      meta
    };
  },
  watch: {
    initialValue(n) {
      if (!n) return;
      this.inputValue = this.formatCurrency(n);
    },
    inputValue() {
      clearTimeout(this.sendUpdate);
      this.sendUpdate = setTimeout(() => {
        this.$emit("updated", this.inputValue);
      }, 1000);
    }
  },
  computed: {
    setInputStyle() {
      return {
        "input-title": this.name == "title",
        "input-name": this.name == "first_name" || this.name == "last_name",
        "input-postcode": this.name == "postcode"
      };
    },
    noLabelSymbol() {
      return {
        "-no-label-symbol": this.label == ""
      };
    }
  },
  methods: {
    handleBlur() {
      if (this.inputValue == "") {
        this.inputValue = 0;
      }
      if (this.inputValue.toString().includes(".")) return;
      this.inputValue = this.formatCurrency(this.inputValue);
    },
    currencyFormat(num) {
      return num.toLocaleString();
    },
    keypress($event) {
      $event = $event ? $event : window.event;
      const charCode = $event.which ? $event.which : $event.keyCode;

      const isNotANumber = !(charCode > 47 && charCode < 58),
        isDecimalPoint = charCode === 46,
        currentlyHaveDecimalPoint = /\./g.exec(this.inputValue);

      let stopKeypress = false;

      if (this.inputValue == "0.00") {
        this.inputValue = "";
      }

      if (isDecimalPoint && !currentlyHaveDecimalPoint) {
        return true;
      } else if (isNotANumber) {
        stopKeypress = true;
      }

      if (stopKeypress) {
        $event.preventDefault();
      } else {
        return true;
      }
    }
  },
  mounted() {
    this.$refs.amount.blur();
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/setup/variables";

.TextInput {
  position: relative;
  margin-bottom: calc(1em * 1.5);
  width: 100%;
  &.-amount {
    &:before {
      content: "\00A3";
      color: inherit;
      position: absolute;
      top: calc(50% + 14px);
      transform: translateY(-50%);
      left: calc($spacingBase / 2);
    }
    &.-no-label-symbol {
      &:before {
        content: "\00A3";
        top: calc(50% + 2px);
      }
    }
    input {
      padding-left: $spacingBase;
    }
  }
}

label {
  display: block;
  margin-bottom: 4px;
  width: 100%;
}

input {
  border-radius: 5px;
  border: 2px solid transparent;
  padding: 15px 10px;
  outline: none;
  background-color: $inputBackground;
  width: 100%;
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
}

input:focus {
  border-color: var(--primary-color);
}

.help-message {
  position: absolute;
  bottom: calc(-1.5 * 1em);
  left: 0;
  margin: 0;
  font-size: 14px;
}

.TextInput.has-error input {
  background-color: var(--error-bg-color);
  color: var(--error-color);
}

.TextInput.has-error input:focus {
  border-color: var(--error-color);
}

.TextInput.has-error .help-message {
  color: var(--error-color);
}

.TextInput.success input {
  background-color: var(--success-bg-color);
  color: var(--success-color);
}

.TextInput.success input:focus {
  border-color: var(--success-color);
}

.TextInput.success .help-message {
  color: var(--success-color);
}
</style>
