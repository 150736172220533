import {axiosRequest} from "@/store/actions/abstract/axiosRequest";

export default {
    async fetchUnnassignedEmails({getters, commit}) {
        let response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-manager/unnassigned-emails`,
            "get",
            localStorage.getItem("token")
        );

        commit("setUnassignedEmailCount", response);

        return response;
    },
    async fetchAccountInbox({getters}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-manager/account-inbox`,
            "get",
            localStorage.getItem("token")
        );
    },
    async fetchAllAccountInbox({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-manager/all-account-inbox`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async fetchAssignedEmails({getters}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-manager/assigned-emails`,
            "get",
            localStorage.getItem("token")
        );
    },
    async markRead({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-manager/mark-read`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async markFlag({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-manager/mark-flag`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async assignEmail({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-manager/assign-email`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async searchUnnassignedEmails({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-manager/search-unnassigned`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async searchScheduledEmails({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-manager/search-scheduled-emails`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async searchAssignedEmails({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-manager/search-assigned`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async fetchEmailThread({getters}, uuid) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-manager/email-thread/${uuid}`,
            "get",
            localStorage.getItem("token")
        );
    },
    async fetchLegacyEmailThread({getters}, uuid) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-manager/legacy-email-thread/${uuid}`,
            "get",
            localStorage.getItem("token")
        );
    },
    async fetchEmailAttachments({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-manager/fetch-email-attachments`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async fetchEmailSchedulerList({getters}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-manager/fetch-email-scheduler-list`,
            "post",
            localStorage.getItem("token")
        );
    },
    async deletedEmailSchedule({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-manager/deleted-email-schedule`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async toggleEmailScheduleActive({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-manager/toggle-email-schedule-active`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async addEmailSchedule({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-manager/add-email-schedule`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async checkSchedule({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-manager/check-schedule`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async forwardToPcs({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-manager/forward-to-pcs`,
            "post",
            localStorage.getItem("token"),
            params
        );
    }
};
