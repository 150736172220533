export default {
  mlState: {
    ruleType: null,
    ragEntityRules: null,
    applicationExtractedText: null,
    documentSrc: null,
    textContent: null,
    pdfPage: 0,
    pdfPageCount: 0,
    pdfArray: [],
    extractedTextArray: null,
    emptyValueArray: null,
    extractedText: null,
    mLTextEntities: null,
    mLTextSearchEntities: [],
    entitySelectedIdList: [],
    entitySelectedText: [],
    mlEditEntity: false,
    mlDeactivate: false,
    mLTextBlocks: null,
    mLTextSearchBlocks: [],
    mLSelectedTextEntities: null,
    mLSelectedTextEntitiesArr: null,
    mLSelectedTextEntitiesIndex: null,
    mLSelectedTextEntitiesIndexArr: [],
    mlEditSelection: null,
    mlProcessedSelection: "processing...",
    mLTextUuid: null,
    mLPage: "pdfRef_0",
    excludeEntities: [
      "mix_your_options",
      "registered_scheme_name",
      "pension_provider",
      "power_of_attorney",
      "power_of_attorney_text",
      "pension_earmarking"
    ],
    mlInformationSource: {},
    mlFieldType: {
      adjustable_income: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      },
      power_of_attorney: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "na",
            text: "NA"
          }
        ]
      },
      transfer_value_date: {
        type: "Date"
      },
      life_assurance: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      },
      protect_retirement_age_text: {
        type: "Text"
      },
      safeguard_benefits: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      },
      policy_accept_transfers_in: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      },
      waiver_of_premium_text: {
        type: "Text"
      },
      regular_contributions: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      },
      loyalty_bonus_text: {
        type: "Text"
      },
      protect_retirement_age: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      },
      pension_type: {
        type: "Text"
      },
      annual_management_fee: {
        type: "Text"
      },
      guaranteed_income: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      },
      fees_charges_penalties_text: {
        type: "Text"
      },
      withdrawels_from_this_pension: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      },
      retirement_date: {
        type: "Date"
      },
      advisor_fees: {
        type: "Number"
      },
      pension_earmarking: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "na",
            text: "NA"
          }
        ]
      },
      dob: {
        type: "Date"
      },
      name_of_employer: {
        type: "Text"
      },
      policy_permits_full_transfer_out: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      },
      created_at: {
        type: "Date"
      },
      tax_free_cash_greater_than_25_perc: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      },
      transfer_value: {
        type: "Number"
      },
      pension_name: {
        type: "Text"
      },
      singular_contributions: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      },
      crystallised_pension: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      },
      loyalty_bonus: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      },
      fund_management_fee: {
        type: "Text"
      },
      policy_number: {
        type: "Text"
      },
      take_cash_in_chunks: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      },
      pension_scheme_tax_reference: {
        type: "Text"
      },
      power_of_attorney_text: {
        type: "Text"
      },
      death_benefits: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      },
      with_profits_fund: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      },
      safeguard_benefits_text: {
        type: "Text"
      },
      death_benefits_text: {
        type: "Text"
      },
      mix_your_options: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      },
      fund_value_date: {
        type: "Date"
      },
      pension_provider: {
        type: "Text"
      },
      life_assurance_text: {
        type: "Text"
      },
      registered_scheme_name: {
        type: "Text"
      },
      fund_value: {
        type: "Number"
      },
      fees_charges_penalties: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "na",
            text: "NA"
          }
        ]
      },
      waiver_of_premium: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      },
      contributions_currently_being_made: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      },
      employer_contribution: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      },
      fund_switching_fees: {
        type: "Text"
      },
      policy_commence_date: {
        type: "Date"
      },
      take_your_whole_pot: {
        type: "Enum",
        values: [
          {
            id: "yes",
            text: "Yes"
          },
          {
            id: "no",
            text: "No"
          }
        ]
      }
    },
    isEntityUpdating: false
  }
};
