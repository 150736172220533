import {axiosRequest} from "@/store/actions/abstract/axiosRequest";

export default {
    async getAggregationReport({getters, commit}, tpuuid) {
        let response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/pension-report/${tpuuid}`,
            "get",
            localStorage.getItem("token")
        );

        let ragEntityRules = getters.getRagEntityRules;
        let pensionReportData = response.data.data;

        for (let i in ragEntityRules) {
            let rule = ragEntityRules[i];
            for (let j in pensionReportData) {
                let entity = pensionReportData[j];
                if (j === i) {
                    entity.value = rule.value;
                }
            }
        }

        commit("setAggregationReport", response.data.data);
        return response;
    },
    async updateAggregationReport({getters, commit}, {tpuuid, tempParams}) {
        let response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/pension-report/${tpuuid}`,
            "put",
            localStorage.getItem("token"),
            tempParams
        );

        if (response.data.data.tracedPension?.aggregationReport) {
            commit(
                "setAggregationReport",
                response.data.data.tracedPension?.aggregationReport
            );
        }
        if (response.data.data.aggregationReport) {
            commit("setAggregationReport", response.data.data.aggregationReport);
        }

        // if (response.data.data.tracedPension) {
        //   commit("setTracedPension", response.data.data.tracedPension);
        // }
        // if (response.data.data.tracedPension?.application) {
        //   commit("setApplication", response.data.data.tracedPension.application);
        // }
        // if (response.data.data.tracedPension?.application.employment) {
        //   commit(
        //     "setEmployments",
        //     response.data.data.tracedPension?.application.employment
        //   );
        // }
        return response;
    },
    async generateAggregationReport({getters}, {auuid}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/pension-report-generate/${auuid}`,
            "post",
            localStorage.getItem("token")
        );
    },
    async sendAggregationReport({getters}, tpuuid) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-aggregation-report/${tpuuid}`,
            "post",
            localStorage.getItem("token")
        );
    }
};
