import axios from "axios";

// axiosInit();

async function axiosExternalRequest(url, type, data) {
  let t = type.toLowerCase();
  let auth = {
    headers: {
      "X-API-KEY": process.env.VUE_APP_DOCUMENT_SERVICE_KEY,
      "Content-Type": "application/json"
    }
  };
  switch (t) {
    case "get":
    case "delete":
      return await axios[t](url);
    case "post":
    case "put":
    case "patch":
      return await axios[t](url, data, auth);
  }
}

async function axiosInit() {
  axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if(!error?.response) return;
      if (
        error.response.statusText === "Unauthorized" &&
        !error.response.request.responseURL.includes("/me")
      ) {
        window.location = "/login?url=" + window.location;
      }
      return Promise.reject(error);
    }
  );
}

async function axiosRequest(url, type, token, data) {
  let t = type.toLowerCase();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  switch (t) {
    case "get":
    case "delete":
      return await axios[t](url, config);
    case "post":
    case "put":
    case "patch":
      return await axios[t](url, data, config);
  }
}

function redirectToLogin(err) {
  if (err.response.status == 401) {
    window.location = "login";
  }
}

export { axiosRequest, redirectToLogin, axiosExternalRequest };
