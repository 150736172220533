import {axiosRequest} from "@/store/actions/abstract/axiosRequest";

export default {
    async getInsight(
        {getters},
        {from_date = null, to_date = null, type = null}
    ) {
        let url = null;
        if (!from_date) {
            url = `${getters.getApiUrl}/${getters.getApiVersion}/mi-report/get-insight`;
        } else {
            url = `${getters.getApiUrl}/${getters.getApiVersion}/mi-report/get-insight?from=${from_date}&to=${to_date}`;
        }
        return await axiosRequest(url, "post", localStorage.getItem("token"), {type});
    }
};
