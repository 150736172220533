<template>
  <div class="edit-wrap">
    <div class="dropdown">
      <button
        class="btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <slot name="btn-text"></slot>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a :class="['dropdown-item', {'-hide' : typeof $slots['btn-add'] === 'undefined'}]" href="#" @click.prevent="handleAdd">
          <slot name="btn-add"></slot>
        </a>
        <a :class="['dropdown-item', {'-hide' : typeof $slots['btn-view'] === 'undefined'}]" href="#" @click.prevent="handleView">
          <slot name="btn-view"></slot>
        </a>
        <a :class="['dropdown-item', {'-hide' : typeof $slots['btn-remove'] === 'undefined'}]" href="#" @click.prevent="handleRemove">
          <slot name="btn-remove"></slot>
        </a>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div>
        <div class="edit-modal" v-if="showAdd">
          <h5 class="mt-4 mb-2">
            <slot name="formAddTitle"></slot>
          </h5>
          <div class="card mb-4">
            <div class="card-body">
              <slot name="formAdd"></slot>
            </div>
          </div>
        </div>
        <div class="edit-modal" v-if="showView">
          <h5 class="mt-4 mb-">
            <slot name="formShowTitle"></slot>
          </h5>
          <div class="card mb-4">
            <div class="card-body">
              <slot name="formShow"></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "EditModal",
  props: {
    align: {
      default: "right"
    }
  },
  emits: ["remove"],
  data: () => ({
    show: false,
    showAdd: false,
    showView: true
  }),
  methods: {
    handleClose() {
      this.showView = false;
      this.showAdd = false;
    },
    handleAdd() {
      this.showView = false;
      this.showAdd = true;
    },
    handleView() {
      this.showAdd = false;
      this.showView = true;
    },
    handleRemove() {
      this.showAdd = false;
      this.showView = true;
      this.$emit("remove");
    }
  }
};
</script>
<style lang="scss" scoped>
.edit {
  &-btn {
    position: absolute;
    top: $spacingBase;
    right: $spacingBase;
    color: $brandBlue;
    text-decoration: underline;
    border: 0;
    background: transparent;
  }
  &-bg {
    position: fixed;
    overflow-y: scroll;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding-top: $spacingBase;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 998;
    @include blurFilter();
  }
  &-control {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
    z-index: 999;
  }
  &-modal {
    pointer-events: all;
    width: 100%;
    z-index: 1000;
  }
  &-wrap {
    .dropdown-item {
      &.-hide {
        padding: 0;
      }
    }
  }
}
</style>
