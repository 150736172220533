import {axiosRequest} from "@/store/actions/abstract/axiosRequest";

export default {
    async sendDocument({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-preview/send-document/`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
};
