<template>
  <div class="edit-wrap">
    <button @click="handleClick" class="edit-btn" type="button" name="button">
      <slot name="btn-text"></slot>
    </button>
    <transition name="fade" mode="out-in">
      <div class="edit-bg" v-if="show">
        <Close @close.prevent="handleClose" />
        <div :style="[customHeight>0 ? {'height': customHeight + 'px'} : {'height':'100vh'} ]" 
        class="edit-control" @click.prevent="handleClose"></div>
        <div ref="editModal" class="edit-modal">
          <h4 class="mb-5">
            <slot name="title"></slot>
          </h4>
          <div class="card">
            <div class="card-body">
              <slot name="content"></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "InfoModal",
  props: {
    data: {
      default: null
    }
  },
  emits: ["doaction"],
  data: () => ({
    show: false,
    customHeight:0
  }),
   watch:{
    show(val){
    if (val) {
        setTimeout(() => this.handleModalResize(), 1000);
        }
    }
  },
  methods: {
    handleClose() {
      this.show = !this.show;
    },
    handleClick() {
      this.show = !this.show;
      this.$emit("doaction", this.data);
    },
    handleModalResize() {
      this.customHeight = 
      this.$refs?.editModal?.clientHeight && this.$refs.editModal.clientHeight > window.innerHeight ?
      this.$refs.editModal.clientHeight :
      window.innerHeight
    }
  }
};
</script>
<style lang="scss" scoped>
.mod-assign {
  .edit-modal {
    width: 40vw;
    max-width: 800px;
  }
}
.edit {
  &-btn {
    color: $brandBlue;
    text-decoration: underline;
    border: 0;
    padding: 0;
    background: transparent;
    text-align: left;
  }
  &-bg {
    position: fixed;
    overflow-y: scroll;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding-top: $spacingBase;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 998;
    @include blurFilter();
  }
  &-control {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
    z-index: 999;
  }
  &-modal {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: all;
    max-width: 800px;
    z-index: 1000;
    .card-body {
      text-align: left;
      div {
        border-bottom: solid $black 1px;
        padding-bottom: $spacingBase;
        margin-bottom: $spacingBase;
        &:last-child {
          border: 0;
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
