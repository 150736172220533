import {
    axiosRequest,
    redirectToLogin
} from "@/store/actions/abstract/axiosRequest";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export default {
    async fetchProviderList({getters, commit}) {
        let response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/pension-providers`,
            "get",
            localStorage.getItem("token")
        );

        commit("setProviderList", response.data);

        //  build the hashtable and populate with empty children arrays so we won't have to check later
        const hashTable = {};
        const dataTree = [];

        response.data.forEach((prv) => {
            hashTable[prv.id] = {...prv, children: [],};
        });

        //  build the unsearched data tree
        response.data.forEach((prv) => {
            if (prv.parent_id && hashTable[prv.parent_id]) {
                hashTable[prv.parent_id].children.push(hashTable[prv.id]);
            } else {
                dataTree.push(hashTable[prv.id]);
            }
        });

        commit("setProvidersDataTree", dataTree);
        commit("setProvidersHashTable", hashTable);
    },
    async fetchEmploymentList({getters, commit}) {
        let response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/employment/get-finder-employers`,
            "get",
            localStorage.getItem("token")
        );

        commit("setEmploymentList", response.data.data);
    },
    async submitApplication({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async submitPension({getters, commit}, params) {
        const response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application/${getters.getApplicationUuid}/pension`,
            "post",
            localStorage.getItem("token"),
            params
        );
        commit("setApplication", response.data.data);
        return response;
    },
    async submitEmployment({getters}, {params}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application/${getters.getApplicationUuid}/employment`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async submitPensionEmployment({getters, commit}, {params, tpuuid}) {
        const response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/traced-pension/${tpuuid}/employment`,
            "post",
            localStorage.getItem("token"),
            params
        );

        commit("setTracedPension", response.data.data);
    },
    async updateEmployment({getters, commit}, params) {
        let uuid = null;
        if (params.route == "traced-pension") {
            uuid = getters.getTracedPension.uuid;
        } else {
            uuid = getters.getApplicationUuid;
        }
        const response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/${params.route}/${uuid}/employment`,
            "put",
            localStorage.getItem("token"),
            params.tempParams
        );

        if (params.route == "traced-pension") {
            commit("setTracedPension", response.data.data);
        }

        return response;
    },
    async updateApplication({getters, commit}, params) {
        if (params.dob) {
            params.dob = dayjs(params.dob).format("YYYY-MM-DD");
        }

        const response = await axiosRequest(
            `${getters.getApiUrl}/${
                getters.getApiVersion
            }/application/${params.uuid || getters.getApplicationUuid}`,
            "put",
            localStorage.getItem("token"),
            params
        );
        commit("setApplication", response.data.data);
        return response;
    },
    async updateTracedPension(
        {getters, commit},
        {tempParams, update = true}
    ) {
        const response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application/${getters.getApplicationUuid}/pension`,
            "post",
            localStorage.getItem("token"),
            tempParams
        );
        if (update) {
            commit("setApplication", response.data.data);
            commit(
                "setTracedPension",
                response.data.data.traced_pension.filter(
                    i => i.id == tempParams.pensions[0].provider.id
                )
            );
            commit("setAggregationReport", response.data.data.pension_report);
        }
        return response;
    },
    async updatePensionProvider({getters}, {tempParams}) {
        const response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/pension-providers/${tempParams.provider_id}`,
            "put",
            localStorage.getItem("token"),
            tempParams
        );
        const responseAddress = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/pension-providers/${tempParams.provider_id}/address`,
            "post",
            localStorage.getItem("token"),
            tempParams
        );
        const responseContact = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/pension-providers/${tempParams.provider_id}/contact`,
            "post",
            localStorage.getItem("token"),
            tempParams
        );
        return [response, responseAddress, responseContact];
    },
    async addPensionProvider({getters}, {tempParams}) {
        const response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/pension-providers/`,
            "post",
            localStorage.getItem("token"),
            tempParams
        );
        let id = response.data.id;
        const responseAddress = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/pension-providers/${id}/address`,
            "post",
            localStorage.getItem("token"),
            tempParams
        );
        const responseContact = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/pension-providers/${id}/contact`,
            "post",
            localStorage.getItem("token"),
            tempParams
        );
        return [response, responseAddress, responseContact];
    },
    async getTracedPensionFocus({getters, commit}, uuid) {
        const response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/pension/${uuid}`,
            "post",
            localStorage.getItem("token")
        );
        // investigate why back end is returning the wrong appl & trace
        commit("setApplication", response.data.data.application);
        commit("setTracedPension", response.data.data);
        commit("setAggregationReport", response.data.data.pension_report);
        commit("setEmployments", response.data.data.application.employment);
        return response;
    },
    async getApplications({getters}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application/get-all`,
            "post",
            localStorage.getItem("token")
        );
    },
    async searchApplications({getters}, uuid) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application/search/${uuid}`,
            "post",
            localStorage.getItem("token")
        );
    },
    async searchMLApplications({getters}, uuid) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application/search-ml/${uuid}`,
            "post",
            localStorage.getItem("token")
        );
    },
    async getPaginationPage({getters}, url) {
        return await axiosRequest(url, "post", localStorage.getItem("token"));
    },
    async getApplicationsFocus({getters, commit}, uuid) {
        const response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application/${uuid}`,
            "post",
            localStorage.getItem("token")
        );
        commit("setApplication", response.data.data);
        commit("setTracedPension", response.data.data.traced_pension);
        commit("setEmployments", response.data.data.employment);
        commit(
            "setAggregationReport",
            response.data.data.traced_pension.pension_report
        );
        return response;
    },
    async loadTracedPension({getters, commit}, tpuuid) {
        const response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/pension/${tpuuid}`,
            "post",
            localStorage.getItem("token")
        );
        commit("setTracedPension", response.data.data);
        return response;
    },
    async startTrace({getters}, uuid) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application/${uuid}/start-trace`,
            "post",
            localStorage.getItem("token")
        );
    },
    async triggerAction({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/trigger-action`,
            "post",
            localStorage.getItem("token"),
            params
        ).catch(err => redirectToLogin(err));
    },
    async attachTask({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/attach-task`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async uploadFile({getters}, {formData, uuid}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application/${uuid}/upload`,
            "post",
            localStorage.getItem("token"),
            formData
        );
    },
    async copyFileToLocation({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/copy-file-to-location`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async getDocuments({getters}, {uuid, type}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application/${uuid}/get-documents`,
            "post",
            localStorage.getItem("token"),
            {type}
        );
    },
    async previewEmail({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-preview/preview-email`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async sendEmail({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/email-preview/send-email`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async getSignature({getters}, {uuid}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application/${uuid}/get-signature`,
            "post",
            localStorage.getItem("token"),
            {uuid}
        );
    },
    async getEmailTrail({getters}, {uuid}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application/get-email-trail/${uuid}`,
            "get",
            localStorage.getItem("token")
        );
    },
    async getFailedEvents({getters}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/pension/display-events`,
            "get",
            localStorage.getItem("token")
        );
    },
    async filterApplication({getters}, {params}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application/filter`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async fetchStatusList({getters}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application/status-list`,
            "get",
            localStorage.getItem("token")
        );
    },
    async updateApplicationTest({getters, commit}, params) {
        const response = await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application/${getters.getApplicationUuid}/update-test-application`,
            "post",
            localStorage.getItem("token"),
            params
        );
        commit("setApplicationTest", params);
        return response;
    },
    async getDashboardStats({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application/get-dashboard-stats`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
    async getLorReadyPensions({getters}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/pension/get-lor-ready-pensions`,
            "get",
            localStorage.getItem("token")
        );
    },
    async getPensionsByStatus({getters}, {searchParams}) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/pension/get-status`,
            "post",
            localStorage.getItem("token"),
            searchParams
        );
    },
    async lazyLoad({getters}, url) {
        return await axiosRequest(
            url,
            "get",
            localStorage.getItem("token"),
        );
    },
    async getUploadTypes({getters}, uuid) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/application/upload-types/${uuid}`,
            "get",
            localStorage.getItem("token"),
        );
    },
    async deleteFile({getters}, params) {
        return await axiosRequest(
            `${getters.getApiUrl}/${getters.getApiVersion}/delete-file`,
            "post",
            localStorage.getItem("token"),
            params
        );
    },
};
